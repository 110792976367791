.herobanner {
  padding: 2rem 2rem;
  aspect-ratio: 360/101;

  &__headeraboutus {
    .herobanner__about {
      padding: 0 2rem;
      max-width: 50%;
    }

    .herobanner__footerimg {
      img {
        aspect-ratio: 1440 / 550;
      }
    }
  }

  &__about {
    flex: 0 0 90%;
    max-width: 50%;
    padding-bottom: 4rem;

    @include breakpoint($device-large) {
      max-width: 50%;
    }

    @include breakpoint($device-xlarge) {
      max-width: 35%;
    }

    @include breakpoint($device-xxlarge) {
      max-width: 35%;
    }

    &__article {
      flex: 0 0 90%;
      max-width: 90%;
      padding: 14rem 1rem 0rem 20px;

      &--title {
        width: 40%;
        padding: 0.5rem 0;
        margin: 0;
      }
    }

    &--subtext {
      font-weight: 200;
      @include font-size(12.5);
      margin: 0;
    }

    &--tag {
      @include font-size(12.5);
      font-weight: 200;
      margin: 0;
      padding: 3.125rem 0;
    }
  }

  &__rightarr {
    flex: 0 0 50%;
    justify-content: flex-end;
  }

  &__article {
    flex: 0 0 90%;
    max-width: 90%;

    &--title {
      width: 80%;
    }
  }

  &__txtarrow {
    height: 100%;
    display: flex;
    margin-top: auto;
    flex: 0 0 10%;

    &--label {
      @include font-size(10);
    }

    &--arrow {
      @include font-size(20);
      color: $white;
    }
  }

  &__video {
    position: absolute;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    iframe {
      position: relative;
      top: 0px;
      left: 0;
      width: 100%;
      border: 0;
      height: 900px;
    }
  }
}
