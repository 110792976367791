.sitefooter {
    display: flex;
    flex-direction: column;
    padding: 3.7rem 1rem;

    &--divider{
        width: 100%;
        height: 2px;
        background-color: $offWhite;
    }

    &__container{
        display: flex;
        flex-direction: column;
        gap: 4.5rem 0;
        padding: 3.75rem 0 0 0;
    }

    &__nav {
        display: flex;
        list-style: none;
        gap: 1.25rem 0;
        flex-wrap: wrap;
        justify-content: flex-end;
        
        &__section {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin: 0;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
            flex: 0 0 50%;  
            gap: 1.25rem;
            
            li {
                @include font-size(10);
                justify-content: flex-start;
                align-items: center;
                display: inline-flex;

                a {
                    text-decoration: none;
                    color: $themeGray;
                }
            }
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        &--logo {
            flex: 0 0 50%;
            max-width: 93px;
            justify-content: center;
            display: flex;
            align-items: center;

            img {
                width: 100%;
            }
        }

        &__social {
            flex: 0 0 50%;
            display: flex;
            gap: 1.25rem;
            justify-content: flex-end;
            align-items: center;

            img {
                width: 25px;
            }
        }

        &--copyright {
            display: inline-flex;
            justify-content: flex-start;
            width: 100%;
            align-items: center;
            @include font-size(10);
            color: $themeGray;
            padding-top: 1.87rem;
        }
    }
}