h1,
.h1 {
  font-size: auto-clamp(49px, 61px, 1440px);
  line-height: auto-clamp(59px, 71px, 1440px);
}

h2,
.h2 {
  font-size: auto-clamp(39px, 49px, 1440px);
  line-height: auto-clamp(49px, 59px, 1440px);
}

h3,
.h3 {
  font-size: auto-clamp(31px, 39px, 1440px);
  line-height: auto-clamp(41px, 49px, 1440px);
}

h4,
.h4 {
  font-size: auto-clamp(25px, 31px, 1440px);
  line-height: auto-clamp(35px, 41px, 1440px);
}

h5,
.h5 {
  font-size: auto-clamp(20px, 25px, 1440px);
  line-height: auto-clamp(30px, 35px, 1440px);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: normal;
  margin-top: 0;
}

.body-large {
  font-size: toRem(20);
}
