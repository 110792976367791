.register {
    display: flex;
    flex-direction: column;
   
    &__left {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        padding: 3.75rem 1rem;

        &--heading {
            @include font-size(19.3);
            font-weight: 300;
        }

        &--subheading {
            @include font-size(10);
        }

        p{
            margin: 0;
        }

        &--pwd {

            &::after {
                cursor: pointer;
                content: "";
                border: 0;
                width: 25px;
                height: 20px;
                right: 0px;
                position: absolute;
                bottom: 0.5rem;
                display: inline-block;
                background-repeat: no-repeat;
                transition: .1s ease-in-out;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25.923' height='15.988' viewBox='0 0 25.923 15.988'%3E%3Cg id='Orion_eye' transform='translate(-1.75 -13.75)'%3E%3Cpath id='layer2' d='M14.712,14.5C7.592,14.5,2.5,21.744,2.5,21.744s5.092,7.244,12.212,7.244,12.212-7.244,12.212-7.244S21.832,14.5,14.712,14.5Z' fill='none' stroke='%23e7a8b1' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3Ccircle id='layer1' cx='4.14' cy='4.14' r='4.14' transform='translate(10.572 17.398)' fill='none' stroke='%23e7a8b1' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3Cpath id='layer1-2' data-name='layer1' d='M28.186,29.5a1.656,1.656,0,0,0,1.449,2.484' transform='translate(-14.923 -8.791)' fill='none' stroke='%23e7a8b1' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
            }
           
        }

        .terms-cb{
            display: flex;
            cursor: pointer;
            justify-content: flex-start;
            @include font-size(8.2);
            gap: 1.25rem;
            input[type="checkbox"] {
                accent-color:$themeGray;
                box-shadow: none;
                width: 20px;
                margin: 0;
                cursor: pointer;
                border: 2px solid $themeGray;
            }

            a{
                text-decoration: underline;
            }
        }


        .input {
            display: flex;
            flex-direction: column-reverse;
            position: relative;
            padding-top: 10px;

            &+.input {
                margin-top: 10px
            }
        }

        .input_label {
            color: $gray900;
            position: absolute;
            top: 20px;
            transition: all 0.3s;
            @include font-size(8.2);
        }

        .input_field {
            border: 0;
            padding: .32rem 0;
            z-index: 1;
            background-color: transparent;
            border-bottom: 1px solid $offWhite;
            font: inherit;
            @include font-size(10);
            line-height: 26px;



            &:focus,
            &:valid {
                outline: 0;
                border-bottom-color: $themeGray;
            }

            &+.input_label {
                color: $gray900;
                transform: translateY(-25px);
            }
        }

        form{
            display: flex;
            flex-direction: column;
            gap: 2.5rem;
        }

        &__cta {
            display: flex;
            align-items: flex-start;
            gap: 2.3rem;
            
            justify-content: space-between;
            padding: 0 0 .65rem 0;
            flex-direction: column;

            &__reg {
                &--ahref {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1.25rem;
                    text-decoration: none;
                    color: $themeGray;
                    @include font-size(10);
                    font-weight: 300;

                    &::before {
                        content: '';
                        @include cta-round-button($accentRed, $white, 40);
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                    }
                }
            }

            &__forgotpwd {
                &--ahref {

                }
            }
        }
    }


    &__right {
        display: flex;
        flex-direction: column;
        padding: 0;
        color: $white;
       
    }


    &-glide {
        display: flex;
        margin: 0;
        flex: 0 0 100%;
        cursor: none !important;

        .glide__arrows {
            margin-top: 23rem;
            width: max-content;
            margin-left: 1.8rem;
            height: max-content;
            position: inherit;
            .disable {
                cursor: not-allowed;
                pointer-events: none;
                color: $red;
                 }
        }
        .glide__arrow {
            background: transparent;

            &--prev {
                @include prevArrow();
            }

            &--next {
                @include nextArrow();
                margin: 0 3rem;
            }
        }

        .glide__track {
            width: 100%;
        }


        .glide__slides {
            @include scrollbar($borderGray, $offWhite);
            display: flex;
            list-style: none;
            justify-content: flex-start;
            padding: 0;
            margin: 0;
            padding-bottom: 3.75rem;
            gap: 0;
            cursor: auto;
        }

        .glide__slide {
            margin: 0 !important;
            width: 0;
        }
    }

    &__litem {
        width: 100% !important;
        margin: 0;
    }

    &-card{
        display: flex;
        flex-direction: column;
        @include bgimageconfig();
        @include background-shade();

        &--imgwrapper{
            display: none;
        }

        &__content{
            display: flex;
            flex-direction: column;
            z-index: 2;
            padding: 20rem 1rem 6.8rem 1rem;
            gap: .62rem;

            p{
                margin: 0;
            }
        }


        &--subtitle{
            @include font-size(8.12);
            font-weight: 300;
        }

        &--title{
            @include font-size(15.6);
            font-weight: 300;
        }

        &--detail{
            @include font-size(10.2)
        }

        
    }
}