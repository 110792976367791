.prefmng{
    padding: 5rem 3rem;

    
    &__header {
        @include font-size(24.38);
        font-weight: normal;
    }

    &__container {
        flex-direction: row;
        padding-top: 3.75rem;
        border: none;

    }

    &__sidenav {
        flex: 0 0 25%;
        border-right: 1px solid $offWhite;

        .dacb-dropdown {
            display: none;
        }

        &__menu {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 0;
            gap: 1.56rem;
            li {
                @include font-size(12.5);
                cursor: pointer;
                color: $gray900;
            }
            .active{
                color: $themeGray;
            }
        }
    }

    &__form{
        flex: 0 0 75%;
        max-width: 30%;
        padding: 0 0 0 3.125rem;
        border-top: none;
        
    }

}