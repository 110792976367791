.myaccount {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 1rem;


    #profilePic{
        display: none;
    }
    &__header {
        @include font-size(24.38);
        font-weight: normal;
    }

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__sidenav {
        display: flex;
        flex-direction: column;

        &__list {
            padding: 0;

            li {
                list-style: none;
            }
        }

        &__menu {
            display: none;
        }
    }

    &__imgwrapper {
        width: 100px;
        overflow: hidden;

        img {
            width: 100%;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        padding: 2.5rem 0;
        border-top: 1px solid $offWhite;
        

        
        &__profile,  &__pwd{
            display: flex;
            flex-direction: column;
        }

        &__pwd{
            
                &::after{
                    content: "";
                    border: 0;
                    width: 25px;
                    height: 20px;
                    right: 0px;
                    position: absolute;
                    bottom: 0.5rem;
                    display: inline-block;
                    background-repeat: no-repeat;
                    transition: .1s ease-in-out;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25.923' height='15.988' viewBox='0 0 25.923 15.988'%3E%3Cg id='Orion_eye' transform='translate(-1.75 -13.75)'%3E%3Cpath id='layer2' d='M14.712,14.5C7.592,14.5,2.5,21.744,2.5,21.744s5.092,7.244,12.212,7.244,12.212-7.244,12.212-7.244S21.832,14.5,14.712,14.5Z' fill='none' stroke='%23e7a8b1' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3Ccircle id='layer1' cx='4.14' cy='4.14' r='4.14' transform='translate(10.572 17.398)' fill='none' stroke='%23e7a8b1' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3Cpath id='layer1-2' data-name='layer1' d='M28.186,29.5a1.656,1.656,0,0,0,1.449,2.484' transform='translate(-14.923 -8.791)' fill='none' stroke='%23e7a8b1' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
                }

            &__rules{
                display: flex;
                flex-direction: column;
        
                &--heading{
                    font-weight: 300;
                    @include font-size(10);
                }
            }
        
            &--rules{
                display: flex;
                flex-direction: column;
                padding: 0px;
                list-style: none;
                gap: .75rem;

                li{
                    font-weight: 400;
                    @include font-size(8.125);
                    
                    &:before {
                        content: "-";
                        color: $themeRed;   
                        margin-right: .6rem;
                    }
                }
            }
        }

        .red{
            color:$themeRed;
        }
        
        
        &--error, &--success{
            justify-content: center;
            align-items: center;  
            display: none;
  
            @include font-size(12);
          }
     
          &--error{
              color: $themeRed;
          }
  
          &--success{
              color: $green;
          }
    }

    &--editxt {
        display: flex;
        margin-top: .5rem;
        @include font-size(10);
        font-weight: 300;
        cursor: pointer;
    }

    &__author{
        display: flex;
        gap: 1rem;
        align-items: center;
        &--img{
          width: 50px;
          height: 50px;
          img{
              width: 100%;
              border-radius: 50%;
          }
        }

        &--title{
            p{
                @include font-size(12)
            }
        }

        &__quicklinks{
            display: flex;
            flex-direction: column;

            .subsector-carousel{
                padding: 0;
            }
        }


    }


    .input {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        padding-top: 10px;

        &+.input {
            margin-top: 10px
        }
    }

    .input_label {
        color: $gray900;
        position: absolute;
        top: 20px;
        transition: all 0.3s;
        @include font-size(8.2);
    }

    .input_field {
        border: 0;
        padding: .32rem 0;
        z-index: 1;
        background-color: transparent;
        border-bottom: 1px solid $offWhite;
        font: inherit;
        @include font-size(10);
        line-height: 26px;

    

        &:focus,
        &:valid {
            outline: 0;
            border-bottom-color: $themeGray;
        }

        &+.input_label {
            color: $gray900;
            transform: translateY(-25px);
        }      
    }

    &__submit , &__ctadelete, &__ctafuser{
        display: flex;

        &--ctalink {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1.25rem;
            text-decoration: none;
            color: $themeGray;
            @include font-size(10);
            font-weight: 300;

            &::before {
                content: '';
                @include cta-round-button($accentRed, $white, 40);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &__ctadelete{
        display: none;
    }

    &__fields {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        padding-top: 2.5rem;
    }


}