.collection {
    &__detail {
       
        &--title {
            @include font-size(24);
        }

        &--desc {
            @include font-size(12);
            width: 80%;
        }
    }


}