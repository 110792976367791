.awards {
  padding: 6.25rem 20.75rem;

  &__header {
    &--title {
      @include font-size(19.38);
      font-weight: normal;
    }
  }

  &__list {
    width: 100%;
    padding: 4.38rem 0;

    &--wrapper {
      flex: 0 0 25%;
      margin: 0;
      border-right: 1px solid $themeGray;
    }

    li:last-child {
      border: none;
    }
  }
}
