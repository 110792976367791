.searchres {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 1rem;

    &__header{
        flex-direction: column;
    }
    &__sidenav {
        display: none;
        list-style: none;
        flex-direction: column;
        padding: 0;
        margin: 0;

        .active {
            color: $themeGray;
        }

        li {
            color: $gray900;
            @include font-size(10);
            cursor: pointer;
        }

        &--facet{
            margin: 0;
            sup {
                padding: .4rem;
            }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
    }

    &__content {
        list-style: none;
        padding: 1.25rem 0;
        margin: 0;
    }

    &__liview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 2.5rem 0;
        border-bottom: 1px solid $ltBorderGray;

        &--subtag {
            @include font-size(10);
            font-weight: 300;
            margin: .625rem 0;
        }

        &--title {
            @include font-size(15.6);
            font-weight: 400;
            margin: 0;
        }

        &--cta {
            @include font-size(10);
            font-weight: 300;
            color: $accentRed;
            justify-content: flex-start;
            align-items: center;
            display: flex;
            gap: 1.25rem;
            padding-top: 1.5rem;

            &:before {
                @include rightLongArrow()
            }

            a {
                color: $accentRed;
            }
        }

        &__people {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1.25rem;
            padding: .38rem 0;

            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                object-fit: cover;
            }

            &__label {
                &--partner {
                    margin: 0;
                    @include font-size(15.6);
                }

                &--place {
                    margin: 0;
                    @include font-size(8.1);
                }
            }
        }

        &__detail {
            display: flex;
            flex-direction: row;
            color: $themeGray;
            @include font-size(8.1);
            font-weight: 400;
            padding: 1.25rem 0 0.25rem 0;

            p {
                margin: 0;

                &::after {
                    content:'';
                    width: 0;
                    height: 100%;
                    border: .5px solid $darkGray;
                    top: 0;
                    margin: 0 1.2rem;
                }
            }
        }


        &::after {
            width: 70%;
            height: 1px;
            background-color: $ltBorderGray;
            margin-left: 0;
        }
    }




}