.keylocs {
    display: flex;
    flex-direction: column;
    padding: 5rem 1rem;

    &__header{
        display: flex;
        flex-direction: column;
    }
    
    &--key{
        margin: 0;
        @include font-size(8.1);
        font-weight: 400;
        padding: .625rem 0;
    }

    &--special{
        @include font-size(24.38);
        font-weight: normal;
        margin: 0;
    }

    &--desc{
        padding: 1.87rem 0;
        margin: 0;
    }

    &__viewall{

        &--ahref {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1.2rem;
            color: $themeGray;
            text-decoration: none;
            @include font-size(10);
            &::before {
                content: '';
                @include cta-round-button($accentRed, $white, 40);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &__list{
        display: none;
        flex-direction: column;  
        gap: 5rem; 
    }

    &-glide {
        display: flex;
        padding: 1.8rem 0;

        .glide__arrows {
            display: none;
        }

        .glide__slides {
            @include scrollbar($borderGray, $offWhite);
            display: flex;
            list-style: none;
            justify-content: flex-start;
            padding: 0;
            padding-bottom: 3.75rem;
            gap: .5rem;
            width: auto !important;
            transform: translate3d(0px, 0px, 0px) !important;

            .glide__slide {
                margin-top: 0;
                max-width: max-content;
                margin: 0;
                flex: 0 0 90%;
                max-width: 90%;
                margin-right: 0 !important;
            }


        }
    }

    
}