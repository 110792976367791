.herobanner {
  padding: 1rem 2rem;
  @include bgimageconfig();
  @include background-shade();
  aspect-ratio: 75/88;

  align-items: flex-end;
  display: flex;

  @media only screen and (min-width: $large-mobile) {
    aspect-ratio: 360/101;
  }

  &__headeraboutus {
    background: $white;
    padding: 1rem 2rem;
    flex-direction: column;
    aspect-ratio: unset;

    &:before {
      background: none;
    }
    .herobanner__txtarrow--label {
      color: $themeGray;
      a {
        color: $themeGray;
      }
    }
    .herobanner__about {
      color: $themeGray;
    }

    .herobanner__txtarrow--arrow {
      @include downLongRedArrow();
    }
    .herobanner__about--tag {
      visibility: hidden;
      padding: 2rem 0;
    }

    .herobanner__footerimg {
      img {
        aspect-ratio: 375 / 500;
        object-fit: cover;
        height: auto;
      }
    }
  }

  &__footerimg {
    display: flex;
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  a {
    color: $white;
    text-decoration: none;
  }

  .accent-color {
    color: $secondaryRed;
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  &__about {
    flex: 0 0 90%;
    max-width: 90%;
    color: $white;
    z-index: 2;

    &--title {
      @include font-size(24.38);
      font-weight: normal;
      margin: 0;
    }

    &--subtext {
      @include font-size(12.5);
    }

    &--tag {
      @include font-size(10);
    }
  }

  &__rightarr {
    display: flex;
    flex: 0 0 10%;
    justify-content: flex-end;
  }

  &__cta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 0;

    &--btn {
      @include cta-round-button($themeRed, $white, 32);
    }
  }

  &__txtarrow {
    display: none;
    justify-content: end;
    align-items: center;
    flex-direction: column;
    gap: 1.25rem;
    z-index: 2;

    &--label {
      text-orientation: mixed;
      color: $white;
      margin: 0;
      -webkit-transform: rotate(-90deg);
    }

    &--arrow {
      margin-top: 2rem;
      @include downLongArrow();
    }

    &--arrow--right {
      @include rightLongArrow();
    }
  }

  &__article {
    // flex: 0 0 100%;
    // padding: 8rem 1rem 3rem 0;
    color: $white;
    z-index: 2;
    width: 90%;

    &__header {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.8rem;
    }

    &--title {
      @extend .h4;
      width: 100%;
      margin: 0.5rem 0;
    }

    &--category {
      @include font-size(7);
    }

    &--divider {
      width: 1px;
      height: 0.8rem;
      background-color: $white;
    }

    &--xtime {
      @include font-size(7);
    }
  }
}
