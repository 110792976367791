.sector {

    &__keycontacts {
        padding: 4rem 7.25rem;
        margin: 0 3rem;

        &__header {
            flex-direction: row;
            justify-content: space-between;
        }
    }

}