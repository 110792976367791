.subsector-carousel {
  display: flex;
  flex-direction: column;
  padding: 1.88rem 1rem;
  width: 100%;
  background-color: $backgroundGray;

  &__header {
    display: flex;
    flex-direction: column;

    &__left {
      display: flex;
      flex: 0 0 100%;
      justify-content: flex-start;
      align-items: center;

      h3 {
        @include font-size(24.38);
        margin: 0;
        font-weight: normal;
      }
    }

    &__right {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      flex: 0 0 100%;

      p {
        &:first-child {
          margin-top: 0;
        }

        margin: 1.25rem 0;
        @include font-size(12.5);
        font-weight: 200;
      }
    }

    &__viewall {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1.25rem;
      text-decoration: none;
      color: $themeGray;
      @include font-size(10);
      font-weight: 300;

      &::before {
        content: '';
        @include cta-round-button($accentRed, $white, 40);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      }
    }

    &__ulcta {
      justify-content: space-between;
    }
  }

  &__title {
    margin-top: 0;
  }

  &-glide {
    display: flex;
    padding: 1.8rem 0;

    .glide__arrows {
      display: none;
    }

    .glide__slides {
      @include scrollbar($borderGray, $offWhite);
      display: flex;
      list-style: none;
      justify-content: flex-start;
      padding: 0;
      padding-bottom: 3.75rem;
      gap: 0.5rem;

      &::after {
        content: '';
        flex: auto;
      }

      .glide__slide {
        margin-top: 0;
        max-width: max-content;
        margin: 0;
        margin-right: 0 !important;
      }
    }
  }

  .show-service-card {
    border: 1px solid $gray;
    background-color: $backgroundGray;

    .subsector-card__litem--image {
      display: none;
    }

    .subsector-card__litem-content {
      height: 100%;
      border-top: 3px solid $borderGreen;

      &:hover {
        border-top: 5px solid $borderGreen;
      }
    }
  }

  &__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    &--btn {
      @include cta-round-button($themeRed, $white, 40);
    }
  }
}
