.subsector-card {
  padding: 6.4rem 0;

  .content-wrapper {
    padding: 0 10rem;
  }

  &-header {
    padding: 0 9rem;
    margin-bottom: 4.8rem;
  }

  &--cta {
    @include font-size(20);
    margin: 2.4rem 0 0 0;
  }

  &__list {
    flex-direction: row;
    margin: 0 -1.6rem;
    flex-wrap: nowrap;
  }

  &__litem {
    padding: 0;
    &--image {
      img {
        width: 100%;
      }
    }
    &-content {
      padding: 2.5rem 2.68rem;
    }
    &--tag {
      @include font-size(18);
    }

    &--description {
      height: auto;
      @include font-size(10);
      margin: 1rem 0;
      padding: 0;
      max-height: auto;
    }
  }

  &__grid {
    display: grid;
    grid: auto / repeat(4, 1fr);
    padding: toRem(50);
    gap: toRem(50);
    margin: toRem(60) toRem(-48);
  }
}
