.sitenav2 {
    padding: 3.5rem 1rem 1rem 1rem;
    display: flex;
    flex-direction: column;
    background: $white;
    overflow-y: auto;

    .theme-gray-color {
        color: $themeGray;
    }

    .light-gray-color {
        color: $gray900;
    }

    .mobafter-subemenu::after {
        display: none;
    }

    &--sticky {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 20
    }


    &__left {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2rem;
    }

    &__right {
        display: flex;

    }

    .align-center {
        align-items: center;
    }


    &-header {
        display: none;
        background: $white;
        position: absolute;
        top: 6rem;
        right: 0;
        flex-direction: column;
        order: 2;
        border: 1px solid $offWhite;
        width: 100%;
        z-index: 998;
        height: 100vh;
        justify-content: flex-start;

        &--overlay {
            display: none;
        }

        &:after {
            display: none;
            content: "";
            width: 100%;
            height: 2px;
            border-bottom: 1px solid $offWhite;
            position: absolute;
            top: 3rem;
        }

        &__container {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }

        &--closebtn {
            @include clearButton("100%");
            justify-content: flex-end;
            padding: 1rem;
            display: none;

            img {
                width: 1rem;
            }
        }

        &__menu {
            display: none;
            list-style: none;
            margin: 0;
            padding: 0;
            order: 2;
            width: 100%;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;

            &:hover .menuitem--toggle {
                text-decoration: none;
                text-decoration-color: $secondaryRed;
            }

            &:after {
                content: "";
                width: 100%;
                height: 2px;
                border-bottom: 1px solid $offWhite;
                position: absolute;
                top: 4rem;
                left: 0;
            }

            &--scrolled {
                &:after {
                    display: none;
                }
            }

        }

        &__menuitem {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            display: flex;
            padding: 0.75rem 0;

            &:after {
                @include rightChevron();
            }

            &--toggle {
                text-decoration: none;
                color: $themeGray;
                @include font-size(19.3);
                line-height: 1.5;
                position: relative;
                letter-spacing: 0.005em;

                &:focus {
                    outline: none;
                }
            }
        }

        &__submenu {
            background: $white;
            z-index: 995;
            display: none;
            flex-direction: column;
            padding: 0;
            width: 100%;

            &__ulcontent {
                gap: 1.2rem;

                &--divider {
                    width: 100%;
                    background: $offWhite;
                    height: 1px;
                }

                &--title {
                    @include font-size(19.35);
                    padding: 0.25rem 0;
                    margin: 0;
                    color: $themeGray;
                    border-bottom: 1px solid $themeRed;
                    max-width: max-content;
                }
            }

            &__main {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                gap: 2.5rem;
            }

            &::-webkit-scrollbar {
                width: 1px;
            }

            .input-group-text {
                background: $white;
            }

            .form-control {
                border-right: 0px;
            }

            &__cross {
                cursor: pointer;
                display: none;

                img {
                    width: 25px;
                    height: 25px;
                }
            }

            &__back {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 1.25rem;
                margin-bottom: 2.06rem;

                &--label {
                    @include font-size(12.5);
                    margin: 0;
                    color: $secondaryGray;
                }
            }


            &__header {
                display: flex;
                flex-direction: column;
            }

            &__container {
                display: flex;
                flex-direction: column;
            }

            &--title {
                @include font-size(19.35);
                padding: 0.625rem 0;
                margin: 0;
                color: $themeGray;
            }

            &--pagetitle {
                @include font-size(15.6);
                margin: 0;
                display: none;
            }

            &--desc {
                margin: 0;
                color: $themeGray;
            }

            &__cta {
                padding: 1.875rem 0 0 0;
            }

            &--divider,
            &--divider2 {
                width: 100%;
                height: 2px;
                border-top: 1px solid $offWhite;
            }


            &--item,
            &--itemv2,
            &--itemv3 {
                list-style: none;
                text-decoration: none;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                &--span {
                    text-decoration: none;
                    color: $themeGray;
                    @include font-size(12.5);
                    justify-content: space-between;
                    align-items: center;
                    flex: 0 0 100%;
                    display: flex;
                    gap: .5rem;

             
                    &:after {
                        @include rightArrow();
                    }
                }


            }

            &--itemv3 {
                a {

                    border-bottom: 1px solid $themeRed;
                    max-width: max-content;

                    &:after {
                        display: none;
                    }
                }
            }

            &--itemv2,
            &--itemv3 {
                &:after {
                    display: none;
                }

                &--sup {
                    @include font-size(9)
                }
            }

            &--item {
                list-style: none;
                text-decoration: none;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &--span{
                    text-decoration: none;
                    color: $themeGray;
                    @include font-size(10);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex: 0 0 100%;
                    
                    &:after {
                        @include rightArrow();
                    }
                }
                
            }

            &__containerv1,
            &__containerv2 {
                display: flex;
                flex-direction: column;
                padding: 0;
                margin: 0;
            }


            &__containerv2 {

                &--divider,
                &--divider2 {
                    min-width: 1px;
                    background-color: $offWhite;
                    height: 100%;
                    visibility: hidden;
                }

                &--divider2 {
                    visibility: hidden;
                }
            }

            &__containerv1 {
                gap: 1.56rem;
            }



            &__itemsv1 {
                height: 15rem;
                overflow-y: auto;
            }

            &__itemsv1,
            &__itemsv2,
            &__itemsv3 {
                padding: 0 1rem 0 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                gap: 1.56rem;
                max-height: 16rem;
                overflow-y: auto;
                scrollbar-color: $themeGray;

                li {
                    text-decoration: none;
                }

                .active {
                    a {
                        color: $themeGray;
                    }
                }

                // &::-webkit-scrollbar-track-piece  {
                //     background-color: $lightGray;
                // }

                // &::-webkit-scrollbar-track {
                //     background-color: $lightGray;
                //     width: 1px;
                // }

                // &::-webkit-scrollbar-thumb {
                //     box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
                // }
            }

            &__itemsv3 {
                &__values {
                    display: none;
                    flex-direction: column;
                    margin: 0;
                    padding: 0;
                    flex-direction: column;
                    gap: 1.56rem;
                    overflow-y: auto;
                    max-height: 16rem;
                    padding-right: 2rem;
                }
            }

            &__itemsv2 {
                &__values {
                    display: none;
                    margin: 0;
                    padding: 0;
                    flex-direction: column;
                    gap: 1.56rem;
                    overflow-y: auto;
                    max-height: 16rem;
                    padding-right: 2rem;

                    .active {
                        a {
                            color: $themeGray;
                        }

                    }
                }
            }

            &__cta {
                display: flex;

                &--learntxt {
                    display: flex;
                    gap: 1.25rem;
                    align-items: center;

                    &::before {
                        content: '';
                        @include cta-round-button($accentRed, $white, 32);
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }

        &__cta {
            display: none;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 1.5rem;
            flex-direction: column;
            padding: 2rem 0;
            order: 3;
            width: 100%;


            &--loginbtn {
                border: none;
                background-color: transparent;
                cursor: pointer;
                color: $themeGray;
                @include font-size(12.5);
                padding: 0;
            }

            &--accbtn {
                border: none;
                background-color: transparent;
                cursor: pointer;
                color: $themeGray;
                @include font-size(10);
                padding: 0;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:after {
                    display: flex;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 7.298 4.674'%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l3.122,3.086,3.122-3.086' transform='translate(1039.819 -6170.467)' fill='none' stroke='%23df4661' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                    content: "";
                    border: 0;
                    margin-left: 0.625em;
                    width: 12px;
                    height: 8px;
                    display: inline-block;
                    background-repeat: no-repeat;
                    transition: 0.1s ease-in-out;
                    color: $secondaryRed;
                }
            }


        }

        &__cross {
            cursor: pointer;
            display: none;
            order: 3;
            justify-content: flex-end;
        }

        &__langsel {
            color: $themeGray;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: flex-start;
            cursor: pointer;
            width: 100%;
            flex-direction: column;

            &--title {
                @include font-size(12.5);
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                &:after {
                    display: flex;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 7.298 4.674'%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l3.122,3.086,3.122-3.086' transform='translate(1039.819 -6170.467)' fill='none' stroke='%23df4661' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                    content: "";
                    border: 0;
                    margin-left: 0.625em;
                    width: 12px;
                    height: 8px;
                    display: inline-block;
                    background-repeat: no-repeat;
                    transition: 0.1s ease-in-out;
                    color: $secondaryRed;
                }
            }



            &__options {
                display: flex;

            }

            &__optionsul {
                display: flex;
                flex-direction: column;
                list-style: none;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                padding: 0;
                margin: 1rem 0;
                gap: 1rem;

                li {
                    color: $themeGray;
                    cursor: pointer;
                    padding: 0.2rem 1rem;
                    @include font-size(10);

                    a {

                        text-decoration: none;
                        color: $themeGray;

                        label {
                            cursor: pointer;
                        }
                    }
                }
            }

            &--cross {
                display: none;
                justify-content: flex-end;
                align-items: center;

                img {
                    width: 20px;
                }
            }
        }


        &__profmenu {
            color: $themeGray;
            display: none;
            position: relative;
            justify-content: center;
            align-items: flex-start;
            cursor: pointer;
            flex-direction: column;
            z-index: 995;


            &--title {
                @include font-size(12.5);
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                &:after {
                    display: flex;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 7.298 4.674'%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l3.122,3.086,3.122-3.086' transform='translate(1039.819 -6170.467)' fill='none' stroke='%23df4661' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                    content: "";
                    border: 0;
                    margin-left: 0.625em;
                    width: 12px;
                    height: 8px;
                    display: inline-block;
                    background-repeat: no-repeat;
                    transition: 0.1s ease-in-out;
                    color: $secondaryRed;
                }
            }



            &__options {
                display: flex;
                flex-direction: column;
                background: $white;
            }

            &__optionsul {
                display: flex;
                flex-direction: column;
                list-style: none;
                justify-content: center;
                align-items: flex-start;
                padding: 0;
                margin: 1rem 0;
                gap: 1rem;

                li {

                    color: $themeGray;
                    cursor: pointer;
                    padding: 0.2rem 1rem;
                    @include font-size(10);


                    .icviewacc {
                        &::before {
                            @include profic-account()
                        }
                    }

                    .icprofpeople {
                        &::before {
                            @include profic-people()
                        }
                    }

                    .icprofcont {
                        &::before {
                            @include profic-content()
                        }
                    }

                    .icprofedit {
                        &::before {
                            @include profic-edit()
                        }
                    }

                    .icprefmng {
                        &::before {
                            @include profic-settings()
                        }
                    }

                    .icprofsignout {
                        &::before {
                            @include profic-signout()
                        }
                    }




                    a {
                        gap: 1rem;

                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        width: 100%;
                        text-decoration: none;
                        color: $themeGray;

                        label {
                            cursor: pointer;
                        }


                        &::before {
                            @include rightLongArrow()
                        }

                    }
                }
            }

            &--cross {
                display: none;
                justify-content: flex-end;
                align-items: center;

                img {
                    width: 20px;
                }

            }
        }

    }

    &--toggle {
        @include clearButton(24);
        height: 24px;

        img {
            max-width: 100%;
        }
    }

    &__searchcontainer {
        display: flex;
        flex-direction: column;
        flex: 0 0 100%;
        margin-top: 0;
    }

    &-searchlogo {
        display: flex;
        align-items: flex-start;
        order: 2;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;

        &__imgwrapper {
            display: flex;
            margin: 0;
            width: 91px;

            img {
                max-width: 100%;
            }
        }

        &__box {
            display: flex;
            float: right;
            line-height: 0;
            flex-direction: column;
            width: 100%;
            margin-top: 2rem;

            &--form {
                display: flex;
                float: right;
                flex-direction: column;
                margin: 0;

                &-expeditor {
                    display: flex;
                    float: right;
                    flex-direction: column;
                    margin: 1rem;
                    padding-left: 1rem;
                }

            }

            input {
                display: inline-block;
                @include font-size(10);
                padding: 1rem 0 1rem 4rem;
                color: $themeGray;
                -webkit-box-sizing: content-box;
                box-sizing: content-box;
                margin: 0;
                outline: 0;
                border-radius: 28px;
                border-left: 0;
                background: $white;
                border-top: 0px;
                border-right: 0px;
                border-bottom: 1px solid $boxShadow;
                filter: drop-shadow(0px 1px 2px $boxShadow);
            }


        }

        &--submit,
        &--searchic {
            color: $secondaryRed;
            z-index: 2;

            justify-content: center;
            align-items: center;
            overflow: hidden;
            background: none;
            border: 0px;
            padding: 0;
            cursor: pointer;
        }

        &--searchic {
            display: none;
            order: 3;
            margin-right: 0;
            margin-left: auto;
        }

        &--submit {
            position: absolute;
            padding: 1.25rem 1.63rem;
            display: flex;
            width: 11px;

            &-expeditor {
                position: absolute;
                padding: 0.5rem 0.63rem;
                display: flex;
                width: 11px;
            }
        }
    }

    &__searchkey {
        display: flex;
        flex-direction: column;
        align-items: flex-start;


        &--title {
            display: flex;
            @include font-size(12.5);
            font-weight: 300;
            padding: 2.5rem 0 1.875rem 0;
        }

        &__updates {
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0;
            width: 100%;
            gap: 1.25rem;


            &--result {
                color: $gray900;
                display: flex;
                width: 100%;

                cursor: pointer;

                a {
                    display: flex;
                    width: 100%;

                    &::after {
                        content: '\27F6';
                        border-width: 0 3px 3px 0;
                        display: inline-block;
                        width: 24px;
                        color: $themeRed;
                        margin-left: auto;
                        right: 0;
                    }
                }


                .active {
                    color: $themeGray;

                    span {
                        &::after {
                            display: none;
                        }
                    }
                }

            }
        }
    }


    &__popularsearch {
        display: flex;
        flex-direction: column;
        align-items: flex-start;


        &--title {
            display: flex;
            @include font-size(12.5);
            font-weight: 300;
            padding: 2.5rem 0 1.875rem 0;
        }

        &__updates {
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0;
            margin: 0;
            width: 100%;
            gap: 1.25rem;


            &--result {
                color: $themeGray;
                display: flex;
                width: 100%;

                cursor: pointer;

                a {
                    display: flex;
                    width: 100%;

                    &::after {
                        content: '\27F6';
                        border-width: 0 3px 3px 0;
                        display: inline-block;
                        width: 24px;
                        color: $themeRed;
                        margin-left: auto;
                        right: 0;
                    }
                }


                .active {
                    color: $themeGray;

                    a {
                        &::after {
                            display: none;
                        }
                    }
                }

            }
        }
    }


}