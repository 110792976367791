.heroheader{
    padding: 0 3.06rem;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &__content{
        flex:  0 0 55%;
        max-width: 55%;
    }
    &__actions{
        align-self: flex-end;
        flex:  0 0 45%;
        max-width: 45%;
        background: transparent;
        gap: 1rem;
        padding: 3.125rem 0;
    }

    &__cta{
        flex: auto;
        background: $white;
        border-radius: 28px;
        flex: 0 0 25%;

        &--services, &--contacts, &--locations, &--resources{
            flex-direction: row;
            padding: .875rem 1.7rem;
            gap: .68rem;
            border: 0;
           
            img{
                height: 15px;
                margin:0;
            }
        }
   
    }
}