.article {
  &__inner {
    padding: 2.5rem 8.06rem;
  }

  &__header {
    flex: 0 0 55%;
    max-width: 55%;
    &__info {
      gap: 1rem;
      flex-wrap: nowrap;
      align-items: center;
      flex-direction: row;
    }
    &-separator {
      display: flex;
    }
  }

  &__overview {
    max-width: 50%;

    &--title {
      font-weight: normal;
      margin-top: toRem(60);
    }
  }

  &__video {
    flex: 0 0 90%;
    max-width: 90%;
  }

  &__headline {
    flex: 0 0 55%;
    max-width: 50%;
    &__title {
      @include font-size(30.63);
    }
  }

  &__content {
    width: 50%;
    overflow: hidden;
    @include font-size(10);
    &--title {
      @include font-size(19.38);
      flex: 0 0 55%;
      max-width: 55%;
    }
    &--imgwrapper {
      min-width: 342px;
      margin-top: 3rem;
      max-height: 600px;
      overflow: hidden;
      flex: 0 0 55%;
      max-width: 55%;
      justify-content: flex-start;
      display: flex;

      img {
        max-width: 100%;
      }
    }

    &--largeimg-wrapper {
      flex: 0 0 100%;
      max-width: 100%;
      justify-content: center;
      align-items: center;
      overflow: hidden img {
        flex-shrink: 0;
        min-width: 100%;
        min-height: 100%;
      }
    }
    &--subtag {
      margin-bottom: 5rem !important;
    }
    &--para {
      flex: 0 0 55%;
      max-width: 55%;
    }
  }

  &__social {
    flex-wrap: nowrap;
    &__cta {
      flex-direction: row;
      justify-content: flex-end;
      width: unset;
      gap: toRem(40);
      display: flex;

      &--downloadpdf {
        cursor: pointer;
        justify-content: right;
        display: flex;
      }
      &--save,
      &--savehref {
        justify-content: right;
        margin-top: 0;
      }
    }
  }

  &__author {
  }

  &__keycontacts {
  }

  &__gated {
    flex: 0 0 55%;
    max-width: 55%;

    &--form {
      gap: 3rem;
    }
    &--title {
      @include font-size(24.38);
      font-weight: normal;
    }

    &--tag {
      @include font-size(12);
    }

    &__fields {
      flex: 0 0 45%;
      max-width: 45%;
    }
  }

  &__grid {
    display: grid;
    grid: auto / repeat(3, minmax(0, 1fr));
    gap: toRem(60) toRem(50);
    padding: toRem(60) 0;

    .articlecard {
      max-width: unset;
    }

    .articlecard--imgwrap {
      width: 100%;
      img,
      &.articlecard__placeholder {
        aspect-ratio: 1/1;
      }
    }
  }

  &__relcontent {
    padding: 0 toRem(50);
    &__carousel {
      .swiper-slide {
        .articlecardv2 {
          max-width: toRem(414);
          img {
            aspect-ratio: 414 / 390;
          }

          &.articlecardv2__largeimgwrap {
            max-width: toRem(646);

            img {
              aspect-ratio: 646 / 390;
            }
          }
        }
      }
    }
  }
}
