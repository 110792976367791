.keylocs {
    flex-direction: row;
    padding: 9.37rem 7.18rem;
    justify-content: space-between;

    &__header{
        display: flex;
        flex-direction: column;
        flex: 0 0 50%;
        justify-content: center;
        max-width: 40%;
    }
    
    &--special{
        @include font-size(24.38);
        font-weight: normal;
    }

    &__list{
        display: flex;
        flex-direction: column;  
        justify-content: flex-start; 
        flex: 0 0 50%;
    }

    &-glide{
        display: none;
    }
}