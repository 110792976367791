.subsector-card {
  padding: 2rem 0;

  &-header {
    padding: 0 3.4rem;
  }

  &--title {
    @extend .h2;
    text-align: center;
  }

  &--cta {
    font-weight: 700;
    letter-spacing: -0.02em;
    padding: 6px 36px;
    @include font-size(20);
    line-height: 1.75em;
    border-radius: 1.6em;
    margin: 2.4rem 0 0 0;
  }

  &__list {
    padding: 0;
    display: flex;
    flex: 0 0 100%;
    list-style: none;
    flex-direction: column;
    justify-content: center;
  }

  &__litem {
    text-align: left;
    list-style: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &--image {
      background: $white;
      border-bottom: 3px solid $accentRed;
      display: flex;
      width: 100%;

      img {
        margin: 0 auto;
        object-fit: cover;
        width: 100%;
        max-height: 100%;
        flex-shrink: 0;
        aspect-ratio: 414 / 220;
      }

      &:hover {
        border-bottom: 6px solid $accentRed;
        cursor: pointer;
      }
    }

    &-content {
      background: $white;
      padding: 2.68rem 1.8rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &--tag {
      font-weight: 500;
      @include font-size(16);
      color: $red;
    }

    &--title {
      color: $blue;
      @extend .h5;
      margin: 0;
    }

    &--description {
      font-weight: 500;
      @include font-size(10);
      color: $darkerGray;
      letter-spacing: -0.02em;
      max-height: auto;
      overflow: hidden;
      font-weight: 200;

      &,
      & + .ewa-rteLine {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    &--ctalink {
      font-weight: 300;
      @include font-size(9);
      color: $themeRed;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1.25rem;
      margin-top: auto;
      padding-top: toRem(40);

      &::before {
        @include rightLongArrow();
      }
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: toRem(50) toRem(16);
    margin: toRem(50) toRem(-16) 0 toRem(-16);
    background: $backgroundGray;

    @media only screen and (min-width: $tablet) {
      display: grid;
      grid: auto / repeat(2, 1fr);
      padding: toRem(50);
      gap: toRem(30);
      margin-top: toRem(60);
    }

    .show-service-card {
      border: 1px solid $gray;
      background-color: $backgroundGray;
      height: 100%;
      width: 100%;

      .subsector-card__litem--image {
        display: none;
      }

      .subsector-card__litem--description {
      }

      .subsector-card__litem--ctalink {
        margin-top: auto;
      }

      .subsector-card__litem-content {
        border-top: 3px solid $borderGreen;
        height: 100%;

        &:hover {
          border-top: 5px solid $borderGreen;
        }
      }
    }
  }
}

.sector {
  .sector--title {
    color: $white;
  }
}
