@mixin background-shade {
  position: relative;

  &:before {
    background: linear-gradient(rgba(0, 0, 0, .39), rgba(0, 0, 0, .45));
    bottom: 0;
    content: '';
    left: 0;
    opacity: .75;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  .wrapper {
    position: relative;
  }
}
