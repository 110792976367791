.breadcrumbs {
  text-align: left;
  z-index: 1;
  padding: toRem(16) toRem(20);

  @media only screen and (min-width: $device-large) {
    padding: toRem(30) toRem(50);
  }

  &::after {
    &:after {
      content: '-';
      margin: 0 0.25rem 0 0.5rem;
    }
  }
  &__list {
    margin: 0;
    border-bottom: 1px solid $offWhite;
    padding: 0 0 toRem(20) 0;
  }

  &__item {
    display: inline-block;

    a {
      font-size: toRem(16);
      line-height: toRem(22);
    }

    &:after {
      content: '/';
      margin: 0 0.25rem 0 0.5rem;
    }

    &:last-child {
      cursor: none;
      pointer-events: none;

      &:after {
        content: none;
      }
    }
  }

  &--no-underline {
    padding-bottom: 0;

    .breadcrumbs__list {
      border-bottom: 0;
    }
  }
}
