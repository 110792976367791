.guideres {
  
     padding: 6.25rem 3.6rem; 
    &__header{
        &--label{
            @include font-size(19.38);
            font-weight: normal;
        }
    }


    &--largecard{
        flex: 0 0 45% !important;
        max-width: 45% !important;

        
    }

    &__container{
        flex-direction: row;
    }

}