.people {
  padding: 3.25rem 0 3.25rem 3.06rem;

  &__gridcont {
    padding: 5rem 3rem;
    color: $themeGray;
    .people__header {
      color: $themeGray;
    }
    .people__header__cta--ahref {
      color: $themeGray;
    }
    .filter-tags__sortby {
      justify-content: flex-end;
    }
  }

  &__header {
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: space-between;
  }

  &__grid {
    padding: 3.75rem 0;
    justify-content: flex-start;
    gap: 2rem;
    &__li {
      flex: 0 0 23%;
    }
  }

  &__card {
    width: 100%;

    &__header {
      padding: 1.875rem;
    }
  }

  &__cardopen {
    width: 100%;
    height: 100%;

    p {
      margin: 0;
    }

    &--profile {
      a {
        padding: 1rem 0;
      }
    }
  }

  &__container {
    padding: 1rem 0 4rem 7.25rem;
  }

  &__header {
    padding: 1.8rem 0;
    &--title {
      @include font-size(30.63);
      font-weight: normal;
    }

    &__viewall {
      margin: 0;
      justify-content: center;
      flex: 0 0 40%;
    }
  }

  &-carousel {
    &__carousel {
      &.swiper {
        padding-top: toRem(44);
        padding-right: toRem(50);
      }
    }
  }
}
