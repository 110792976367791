.sitefooter{
    display: flex;
    padding: 5rem 3.06rem 4.8rem 3.06rem;

    &--divider{
        width: 100%;
        height: 2px;
        background-color: $offWhite;
    }

    &__container{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 4.5rem 0;
        padding: 5rem 0 0 0;
    }


    &__nav{ 
        display: flex;
        flex: 0 0 70%;
       
        align-items: flex-start;
         &__section {
           flex: 0 0 25%;
           gap: 1.25rem;
         }

    }

    &__content{
        flex: 0 0 30%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
       

        &--logo{
            flex: auto;
            max-width: 144px;
            max-height: 50px;
        }

        &__social{
            flex: auto;
               margin-top: 5.5rem;
               max-height: 30px;
        }

        &--copyright{
            padding: 0;
            margin-top: 1.25rem;
        }
    }

}