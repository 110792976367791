.aboutus {
    display: flex;
    flex-direction: column;


    &__header{

    }


    &--title{
        @include font-size(3.06)
    }


    &--desc{
        @include font-size(1.25)
    }
}