.landpage{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__form {
        width: 20%;
        align-items: flex-start;

    }
    &__fields {
        width: 100%;
    }
}