.persondetail {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 toRem(16);
  max-width: toRem(1342);
  margin: 0 auto;

  &__wrapper {
    padding: toRem(30) 0 0;
    grid-area: profile;
  }

  &__profwrapper {
    display: flex;
    position: relative;
    width: fit-content;
  }

  &__imgwrapper {
    top: 200px;
    right: 0;
    grid-area: image;
    width: 100%;
    max-width: 100%;
  }

  &--profileimg {
    max-width: 100%;
    aspect-ratio: 58/53;
    object-fit: cover;
  }

  &__profile {
    &--message {
      width: 20%;
      margin-top: 25px;
    }

    &--name {
      margin: 0;
      @extend .h2;
      color: $themeGray;
    }

    &__tag {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: toRem(16);
      @include font-size(12.5);
      font-weight: 400;
      color: $themeGray;
      padding-bottom: toRem(30);
    }

    &--divider {
      width: 1px;
      height: toRem(16);
      background-color: $themeGray;
    }

    &--divider,
    &--place,
    &--partner {
      margin: 0;
    }

    &--info {
      @include font-size(12.5);
      color: $themeGray;
      margin-top: -15px;
    }

    &__card {
      display: flex;
      flex-direction: column;
      padding: 3.75rem 0 0 0;

      &--row {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: toRem(25) 0;
        border-bottom: 1px solid $offWhite;
        &:first-of-type {
          padding-top: 0;
        }
      }

      &--key,
      &--value {
        @include font-size(10);
      }

      &--key {
        color: $themeGray;
        flex: 0 0 20%;
      }

      &--value {
        flex: 0 0 80%;
        color: $themeRed;
        a {
          color: $themeRed;
        }
      }

      &__link {
        align-items: center;
        gap: 1rem;
        display: flex;
        padding: 0.625rem 0;
        &:first-of-type {
          padding-top: 0;
        }
      }

      &--socicon {
        svg {
          height: 15px;
        }
      }

      &--soclabel {
        @include font-size(10);
        color: $secondaryGray;
        white-space: nowrap;
      }
    }

    &__savecta {
      display: flex;
    }
    &--savetxt,
    &--savehref {
      display: flex;
      justify-content: center;
      align-self: flex-start;
      gap: 1rem;
      @include font-size(10);
      padding: 1.25rem 0 0;
      &::before {
        content: '';
        @include cta-plus();
      }
    }
  }
  &--bsblogo {
    position: absolute;
    width: 50px;
    bottom: 8px;
    right: 0px;
  }
  &__about {
    display: flex;
    flex-direction: column;
    padding: toRem(80) 0 0;
    @include font-size(15.6);
    color: $themeGray;
    grid-area: about;
    &--title {
      margin: 0;
      padding-bottom: 2.5rem;
      font-weight: normal;
    }
    &__accordions {
      display: flex;
      flex-direction: column;
    }
  }

  &__accordion {
    border-style: solid;
    border-width: 1px 0 0 0;
    border-color: $ltBorderGray;
    padding: toRem(10) 0;
    width: 100%;
    overflow: hidden;

    &--active {
      .persondetail__accordion__heading {
        color: $secondaryGray;
      }
      .persondetail__accordion--toggle {
        svg {
          fill: $black;
          path {
            fill: $black;
          }
        }
      }
    }
    &__intro {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      @include font-size(12.5);
    }

    &--heading {
      color: $secondaryGray;
      @include font-size(18);
      line-height: 28px;
      width: calc(100% - 2.185rem);
    }

    &--toggle {
      svg {
        fill: $themeGray;
        width: 16px;
        height: 12px;
        path {
          fill: $themeGray;
        }
      }
    }

    &__content {
      max-height: 0;
      overflow: hidden;
      will-change: max-height;
      transition: all 0.25s ease-out;
      opacity: 0;
      padding: 0;
      margin: toRem(40) 0 0;
      list-style: none;

      &--title {
        color: $themeGray;
        @include font-size(11.5);
        margin: 0;
        padding: 0.425rem 0;
      }
      &--detail {
        color: $paraGray;
        @include font-size(10);
        margin: 0;
        padding: 0.45rem 0;
        text-align: left;
      }
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  .accordion {
    &--open {
      .persondetail__accordion__content {
        opacity: 1;
        margin: toRem(40) 0;
      }
    }
  }

  &__office {
    display: flex;
    flex-direction: column;
    grid-area: office;
    padding: toRem(80) 0;
    &--title {
      font-weight: normal;
    }
    .offlocard {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      &__content {
        padding: 1.8rem 0 2.5rem 0;
      }
      img {
        width: 100%;
        max-width: 100%;
        aspect-ratio: 646/350;
        object-fit: cover;
      }
      ul {
        margin: 0;
      }
    }
    &--title {
      display: flex;
      font-weight: 300;
      margin: 0 0 toRem(30);
    }
  }

  &__footer {
    display: none;
    position: fixed;
    background: $themeGray;
    width: 100%;
    bottom: 0;
    right: 0;
    padding: 1.5rem 1rem 2rem 1rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    color: $white;
    z-index: 10;
    column-gap: toRem(20);

    a {
      color: #fff;
      display: flex;
      gap: 0.5rem;
    }

    &__contact {
      display: flex;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    &__profile {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      flex: 1 1 55%;
    }

    &--name {
      @include font-size(12.5);
    }

    &__tag {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      p {
        margin-bottom: 0;
      }
    }

    &--partner,
    &--place,
    &--divider {
      margin-top: 5px;
    }

    &--place {
      @include font-size(8.1);
    }

    &--divider {
      width: 1px;
      height: 0.8rem;
      background-color: $white;
    }

    &--call,
    &--email,
    &--save {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      @include font-size(10);
    }

    &--save {
      display: none;
    }

    &--call,
    &--email {
      border: 1px solid white;
      border-radius: 50%;
      height: 45px;
      width: 45px;
    }

    &--call {
      &:before {
        @include cta-phone(21);
        margin: auto;
      }
    }

    &--email {
      &:before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22.705 15.534'%3E%3Cg id='Orion_envelope_3_' data-name='Orion_envelope (3)' transform='translate(-0.94 -10.94)'%3E%3Cpath id='layer2' d='M2,12l10.293,9.4L22.586,12' transform='translate(0 0)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3Cpath id='layer1' d='M2,12H22.586V25.724H2Z' transform='translate(0 0)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
        @include commonProperties();
        width: 22px;
        height: 15px;
        margin: auto;
      }
    }

    &--save {
      &:before {
        @include cta-save-white();
      }
    }

    &-contact-text {
      display: none;
    }
  }
}
