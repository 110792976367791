.sorting {
  display: flex;

  &-container {
    z-index: 99;
    height: 100%;
    right: 0px;
    top: 0px;
    position: fixed;
    width: 100%;
    transition: all 0.5s ease;
    flex-direction: column;
    background: $white;
    overflow-y: auto;
    visibility: hidden;
    color: $themeGray;
    padding: 1rem;

    &.open-side {
      right: 0;
      transition: all 0.5s ease;
      visibility: visible;
    }

    &--overlay {
      cursor: pointer;
      opacity: 0.8;
      backdrop-filter: blur(24px);
      background-color: $darkGray;
      position: fixed;
      z-index: 11;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -o-transition: all 1s;
      -webkit-transition: all 1s;
      transition: all 1s;
    }

    ul {
      list-style: none;
    }

    &__tags {
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 1.2rem;

      li {
        @include font-size(12);
      }

      .active {
        border-bottom: 1px solid $themeRed;
      }
    }

    &--licat {
      padding: 1rem 0;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        border-top: 1px solid $gray500;
        margin-top: -1rem;
      }

      &--header {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0;
        align-items: center;

        @include font-size(12.5);

        span {
          cursor: pointer;

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &--lisubcat {
      display: flex;
      justify-content: space-between;
      padding: 0.7rem 0.5rem;

      label {
        @include font-size(10);
      }

      input[type='checkbox'] {
        accent-color: $themeGray;
        box-shadow: none;
        width: 25px;
        margin: 0;
        cursor: pointer;
        border: 2px solid $themeGray;

        &:checked ~ label {
          &:before {
            border: 2px solid $themeGray;
          }

          &:after {
            transform: rotate(-45deg) scale(1);
          }
        }

        &:focus + label::before {
          outline: 0;
        }
      }
    }

    &--subcat {
      padding: 0;
    }

    &__header {
      display: flex;
      justify-content: flex-end;
      padding: 3.12rem 1rem;
    }

    &--closebtn {
      cursor: pointer;
      border: none;
      background: transparent;
      width: 25px;
      height: 25px;
      padding: 0;

      svg {
        width: 25px;
      }
    }

    &__cta {
      display: flex;
      flex: 1;
      width: 100%;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: toRem(25) toRem(16);

      &--apply {
        background: $secondaryRed 0% 0% no-repeat padding-box;
        color: $white;
        border: 1px solid $secondaryRed;
        cursor: pointer;
        padding: 1rem 3rem;
        justify-content: center;
        align-items: center;
        border-radius: 28px;
        @include font-size(10);
      }
    }
  }
}
