.sector-carousel-boilerplate {
  padding: 0;
  text-align: center;

  a {
    text-decoration: none;
    color: $themeGray;
  }

  * {
    -webkit-tap-highlight-color: transparent;
  }

  &__cardopen {
    background: $lighterGray;
    padding: 0 2rem;
    display: none;
    transition: opacity 3s ease-out;
    opacity: 0;
    flex-direction: column;
    cursor: pointer;

    &__header {
      padding: 1rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__learnmore {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
    }

    &__learnmore__bottom {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      margin-top: auto;
      padding-bottom: 40px;
    }

    &--title {
      bottom: 20;
      font-weight: 400;
      @include font-size(15.63);
      text-align: left;
      color: $black;
      margin: 0;
    }

    &--closebtn {
      @include font-size(14, 1);
      transform: rotate(45deg);
      @include cta-round-button($white, $themeRed);
    }

    &--learnbtn {
      @include cta-round-button($themeRed, $white);
    }

    &--desc {
      @include font-size(10);
      text-align: left;
      color: $black;
    }

    &--learntxt {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1.25rem;
      text-decoration: none;
      color: $themeGray;
      @include font-size(8);
      font-weight: 300;

      &::before {
        content: '';
        @include cta-round-button($accentRed, $white, 30);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      }
    }

    &__services {
      background: $white;
      padding: 0rem 1rem 1rem 1rem;
      margin: 0rem 0rem 1rem 0rem;
      overflow-y: scroll;

      p {
        text-align: left;
      }

      ul {
        list-style: none;
        padding: 0;
        flex-wrap: wrap;
        display: flex;

        li {
          display: inline-flex;
          flex: 0 0 100%;
          padding: 1rem 0;
          justify-content: space-between;
          align-items: center;

          &::after {
            content: '\2192';
            border-width: 0 3px 3px 0;
            display: inline-block;
            width: 11px;
            color: $themeRed;
            padding-right: 1.5rem;
          }
        }
      }
    }
  }

  &__card {
    overflow: hidden;
    width: 100%;
    aspect-ratio: 314 / 350;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center;
    opacity: 1;
    z-index: 9;
    display: flex;
    @include background-shade();

    &--editimg {
      display: none;
    }

    ul {
      list-style: none;
    }

    &__content {
      display: flex;
      flex-direction: column;
      z-index: 10;
      padding: 1rem 2rem;
      overflow: hidden;
      justify-content: space-between;
      height: 100%;
      width: 100%;
    }

    &__cta {
      display: flex;
      flex-direction: row;
      justify-content: right;

      &:hover {
        .sector-carousel-boilerplate__cardopen {
          display: flex;
          opacity: 1;
        }
        .sector-carousel-boilerplate__card {
          display: none;
          opacity: 0;
          height: 0;
          transition: opacity 3s ease-out;
          overflow: hidden;
        }
      }
    }

    &--btn {
      @include cta-round-button($white, $themeRed, 32);
    }

    &--title {
      font-weight: 400;
      @include font-size(15.63);
      font-weight: normal;
      text-align: left;
      color: $white;
      margin: 0;
      padding: 0 0 1rem 0;
    }

    &--desc {
      @include font-size(10);
      color: $white;
      text-align: left;
      margin: 0.31rem 0;
    }
  }

  &-header {
    padding: 0 3.4rem;
  }
}

// .sector-carousel-boilerplate:hover {
//   .sector-carousel-boilerplate__cardopen {
//     display: flex;
//     opacity: 1;
//   }

//   .sector-carousel-boilerplate__card {
//     display: none;
//     opacity: 0;
//     height: 0;
//     transition: opacity 3s ease-out;
//     overflow: hidden
//   }
// }
