.sector-carousel {
  padding: 2rem 0;
  flex-direction: column;

  .solution-cards {
    &__header {
      padding: 2rem 3.06rem;

      display: flex;
      flex-direction: column;

      .sector-carousel__header__right {
        max-width: 50%;
      }
    }
  }

  &__header {
    flex-direction: row;
    gap: 1rem;
    padding: 4rem toRem(165) 0;
    @include breakpoint($device-xxlarge) {
      padding: 6rem 12rem 0 6rem;
    }

    &__left {
      display: flex;
      flex: 0 0 25%;
      justify-content: flex-start;
      align-items: flex-start;

      p {
        @include font-size(20);
      }
      h3 {
        @include font-size(24.38);
        font-weight: normal;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 0 0 75%;
    }

    &__ulcta {
      display: flex;
      list-style: none;
      justify-content: space-between;
      padding: 0;
      flex-direction: row;
      gap: 3.43rem;
      margin-bottom: 3.75rem;
    }
  }

  &__cta {
    justify-content: space-between;
    align-items: center;
    gap: 1.25rem;

    &--btn {
      @include cta-round-button($themeRed, $white, 40);
    }
  }

  &__carousel {
    margin-bottom: 3.75rem;

    .carousel-cell {
      width: 35%;
      margin-right: 2.5%;
    }
  }
}
