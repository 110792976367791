.regions {
  padding: 2.5rem 3rem;

  &__header {
    width: 100%;
    flex-direction: row;
    &__left {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &__right {
      align-items: flex-end;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &__list {
    flex-direction: row;
    justify-content: space-between;
    gap: 3rem;
    flex-wrap: wrap;
    .sector-carousel-boilerplate {
      flex: 0 0 48%;
      max-width: 48%;

      &__card {
        @include background-shade();

        &__content {
          padding: toRem(30);
        }
      }

      &__cardopen {
        &--closebtn {
          margin: toRem(-10);
        }

        &--title {
          margin-top: 0;
        }
      }
    }
  }
}
