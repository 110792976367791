.sector-carousel {
  display: flex;
  padding: 3.75rem 0;
  flex-direction: column;

  .solution-cards {
    &__header {
      display: flex;
      flex-direction: column;
    }

    &__glide {
      .glide__slides {
        justify-content: center;
        flex-direction: column;
        align-items: center;
        overflow-x: hidden;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    &__left {
      display: flex;
      flex: 0 0 100%;
      justify-content: flex-start;
      align-items: center;
      @include font-size(19.2);
      margin: 0;
    }

    &__right {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      padding: 1rem 0;

      p {
        margin: 0;
        @include font-size(12.5);
      }
    }

    &__ulcta {
      justify-content: space-between;
      flex-direction: column;
      gap: 1.56rem;
      margin: 2.5rem 0 0;
      padding: 0;
      list-style: none;

      li {
        margin-bottom: 0.5rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &__cta {
    &--learntxt {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1.2rem;
      color: $themeGray;
      text-decoration: none;
      @include font-size(10);
      &::before {
        content: '';
        @include cta-round-button($accentRed, $white, 40);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &__carousel {
    padding-bottom: 3.75rem;

    .carousel-cell {
      width: 65%;
      margin-right: 3.5%;
    }

    .flickity-page-dots {
      bottom: toRem(10);
    }

    .flickity-page-dot {
      width: toRem(8);
      height: toRem(8);
      margin: 0 toRem(8);

      &.is-selected {
        background: $accentRed;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}
