// ================================== //
//    #VARIABLES
//
// Creates map of theme variables and encode as JSON
// ================================== //

@import 'sass-json-export/stylesheets/sass-json-export';
@import './utility/_settings.colors';

$variables: (
  colors: $color-map
);


body {
  color: $themeGray; 
  a{
    text-decoration: none;
    color: $themeGray;
    cursor: pointer;
  }
}

.gray-color{
  color: $gray900;
}

.theme-gray-color {
  color: $themeGray;
}

.light-gray-color {
  color: $gray900;
}

.noscroll{
  position:fixed;
  overflow:hidden;
}

.dacb-auth-loginreg{
   .button-primary{
     background: $themeRed !important;
     border-color: $themeRed !important;
   }

   .registration-link{
      color: $themeRed !important;
   }
}

.disabled {
  opacity: .3;
  cursor: none;
  pointer-events: none;
}

.enabled{
  opacity: 1 !important;
  cursor: pointer !important;
  pointer-events: all !important;
}

.padding-0{
  padding:0 !important;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background-color:$offWhite;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 3px $themeGray;
}

// https://github.com/oddbird/sass-json-export
@include json-encode($variables, comment);
