.content-wrapper {
    padding: 0 1.6rem;

    @include breakpoint($ipad) {
      padding: 0 4rem;
    }
    @include breakpoint($device-large) {
      padding: 0 6rem;
    }
    @include breakpoint($device-xlarge) {
      padding: 0 10rem;
    }
    @include breakpoint($device-xxlarge) {
      padding: 0 12rem;
    }
}

* {
  box-sizing: border-box 
}
