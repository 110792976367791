.sitenav2 {
    padding: 1.5rem 3.06rem;
    gap: 2.5rem;
    overflow-y: initial;
    flex-direction: row;


    &__left {
        flex: 0 0 78%;
        height: max-content;
        gap: 2.87rem;
    }

    &__right {
        flex: 0 0 20%;
        justify-content: flex-start;
        flex-direction: column;
        gap: 2.8rem;
        height: 48px;
    }

    &-mobheader {
        display: none;
    }

    &-header {
        display: flex;
        flex-direction: column;
        position: relative;
        order: 0;
        justify-content: space-between;
        width: 100%;
        top: 0;
        border: none;
        height: auto;
        gap: 2.5rem;

        &:after {
            display: flex;
        }

        &--overlay {
            position: absolute;
            left: 0;
            top: 3rem;
            width: 100%;
            z-index: 993;
            background: $themeGray;
            height: 100vh;
            opacity: .8 !important;
            display: none;
        }

        &--closebtn {
            display: none;
        }

        &__container {
            flex-direction: row;
        }

        &__menu {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            flex: 0 0 70%;
            padding: 0;
            order: 1;

            .active {
                .sitenav2-header__menuitem--toggle{
                    border-bottom: 1px solid $secondaryRed;
                    max-width: max-content;
                }
            }
        }

        &__menuitem {
            width: auto;
            

            &--toggle {
                @include font-size(7);

                @include breakpoint($device-xlarge) {
                    @include font-size(10);
                }
            }

            &:after {
                display: none;
            }

            padding: 0 1.5rem 0 0;
        }

        &__cross {
            display: none;
        }

        &__cta {
            display: flex;
            flex-direction: row-reverse;
            padding: 0;
            gap: 3.2rem;
            order: 2;
            justify-content: space-between;
            width: auto;
            
            &--loginbtn, &--accbtn {
                @include font-size(6.25);
                width: max-content;
                @include breakpoint($device-xlarge) {
                    @include font-size(10);
                }
            }
        }

        &__submenu {
            position: absolute;
            top: 4rem;
            flex-direction: column;
            padding: 0 7.18rem 9.06rem 7.18rem;
            // padding: 0 3.75rem 9.06rem 3.75rem;
            order: 3;
            left: 0;
            width: 100%;

            &__ulcontent{
                gap: auto;
                &--divider{
                    display: none;
                }
                &--title{
                    display: none;
                }
            }
            &--level2 {
                flex: 0 0 25%;
            }

            &--level3{
                width: max-content;
            }
            &__main {
                flex-direction: row;
            }

            &__cross {
                display: flex;
                justify-content: flex-end;
                padding: 1.56rem 0;
            }


            &__back {
                display: none;
            }

            &__header {
                flex: 0 0 35%;
                padding-right: 4rem;

            }

            &--title {
                @include font-size(24.37);
                padding: 0 0 0.625rem 0;
            }

            &--pagetitle {
                display: none;
            }

            &__containerv1,
            &__containerv2 {
                flex-direction: row;
                gap: 2rem;
                justify-content: flex-start;
            }

            &__containerv1{
            flex: 0 0 60%;
            }

            &__containerv2 {
                flex: 0 0 100%;
                padding: 0 2rem;
            }


            &__itemsv2 {
                padding-right: 1rem;
            }

            &__itemsv1,
            &__itemsv2
             {
                gap: 1.56rem;
                display: flex;
                flex-direction: column;
                height: fit-content;
                max-height: 21rem;

                li {
                    @include font-size(1.25);
                }

                &__values {
                    flex-direction: column;
                    gap: 1.56rem;
                    height: fit-content;
                    max-height: 20rem;
                    flex: 0 0 25%;
                }
            }

            &__itemsv1 {
                flex: 0 0 100%;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: space-between;

            }

            &__itemsv2 {
                flex: 0 0 25%;
                margin: 0;

                &__values{
                    .active{
                    a{
                        &::after{
                            display: none;
                        }
                    }
                }
                }
                a {
                    @include font-size(10);

                    @include breakpoint($device-xlarge) {
                        @include font-size(14)
                    }
                }

                .active {
                    border-bottom: 1px solid $secondaryRed;

                    
                    span {
                        &::after{
                            display: none;
                        }
                    }
                }

                &--sup {
                    @include font-size(9)
                }
            }

            

            &--item {
                flex: 0 0 45%;

                a {
                    @include font-size(10);
                    gap: 1rem;
                    @include breakpoint($device-xlarge) {
                        @include font-size(12.5)
                    }
                }
            }

            &--itemv2 {
                justify-content: flex-start;

            }

       
            &--divider {
                display: none;
            }


        }

        &__langsel {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: max-content;

            &__options {
                flex-direction: row-reverse;
                position: absolute;
                gap: 0.4rem;
                z-index: 997;
                background: $white;
                top: 3rem;
                width: 15rem;
                left: -12rem;
                padding: 1rem;
            }

            &--cross {
                display: flex;
                align-items: flex-start;
            }

            &--title {
                width: max-content;
                justify-content: space-between;
                @include font-size(6.25);

                @include breakpoint($device-xlarge) {
                    width: 120px;
                    @include font-size(10);
                }
            }
        }

        &__profmenu{
            right: 2rem;
            top: 4rem;
            left: auto;
            position: absolute;
            
        }
    }


    &__options {
       padding: 2rem;
    }


    &--cross {
        display: flex;
    }


    &__searchcontainer {
        flex: 0 0 30%;
    }

    &__searchkey {

        &--title {
            padding: 3.75rem 0 1.875rem 0;
        }
    }


    &-searchlogo {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 14.5rem;
        flex-direction: row;
        width: max-content;

        &__imgwrapper {
            display: flex;
            width: 125px;

            @include breakpoint($device-xlarge) {
                width: 181px;
            }
        }

        &__box {
            display: flex;
            flex: 0 0 30%;
            margin-top: 0;

            &--form {
                width: 31.25rem;
            }

            &:after {
                display: none;
            }
        }

        &--searchic {
            display: none;
        }
    }

    &--toggle {
        display: none;
    }


}