.dacb-dropdown {
    display: flex;
    list-style: none;
    flex-direction: column;
    width: 100%;
    margin: 1.25rem 0 2.25rem 0;


    &--label {
        @include font-size(10);
        font-weight: 300;
        display: flex;
        justify-content: space-between;
        padding: .88rem 1.25rem;
        align-items: center;
        border: 1px solid $themeGray;
        border-radius: 2rem;


        &::after {
            // content: '';
            // content: "\25be";
            // color: $accentRed;

            border-style: solid;
            border-width: 0.15em 0.15em 0 0;
            content: '';
            display: inline-block;
            height: 0.45em;
            left: 0.15em;
            position: relative;
            top: 0.15em;
            transform: rotate(-45deg);
            vertical-align: top;
            width: 0.45em;
            color: $accentRed;

            top: 0;
            transform: rotate(135deg);
        }
    }

    &__container {
        display: none;
        flex-direction: column;
        list-style: none;
        margin: 3.25rem 1rem;
        padding: 0.5rem 1rem;
        position: fixed;
        background: $white;
        left: 1rem;
        right: 1rem;
        border: 1px solid $gray100;
    }

    &--item {
        color: $themeGray;
        @include font-size(10);
        font-weight: 300;
        padding: .8rem 0;
    }
}