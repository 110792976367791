.paginator {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;

  &--prevarw,
  &--nextarw {
    flex-shrink: 0;
    display: inline-flex;
    color: $gray900;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  &--firstarw {
    @include doublePrevArrow();
  }

  &--prevarw {
    @include prevCheveron();
  }

  &--nextarw {
    @include rightChevron();
  }

  &--lastarw {
    @include fwdCheveron();
  }

  .disabled {
    opacity: 0.3;
  }

  &--firstarw,
  &--lastarw {
    flex-shrink: 0;
    display: inline-flex;
    color: $gray900;
  }

  &__counter {
    display: flex;
    gap: 1rem;
    padding: 0;
    list-style: none;

    .active {
      background: $themeGray;
      color: $white;
    }
  }

  &--count {
    display: flex;

    border-radius: 2rem;
    justify-content: center;
    align-items: center;

    padding: 1rem;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  &--count-ellipsis {
    pointer-events: none;
  }

  @media only screen and (min-width: $large-mobile) {
    gap: 1.5rem;

    &__counter {
      gap: 1.5rem;
    }
  }
}
