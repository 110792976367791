.awards {
  display: flex;
  margin: 0;
  flex-direction: column;
  align-items: center;
  padding: 2.68rem 3.125rem;
  border-top: 1px solid $offWhite;
  border-bottom: 1px solid $offWhite;

  &__header {
    display: flex;
    flex-direction: column;

    &--caption {
      text-align: center;
      @include font-size(8.13);
      margin: 0;
      font-weight: 300;
    }

    &--title {
      margin: 0;
      @include font-size(15.63);
      font-weight: normal;
      font-weight: 400;
    }
  }

  &__list {
    display: flex;
    margin: 0;
    padding: 1.25rem 0;
    list-style: none;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    &--wrapper {
      display: flex;
      flex: 0 0 50%;
      justify-content: center;
      margin: 2.5rem 0;

      img {
        width: 80;
        height: 80;
        object-fit: cover;
      }
    }
  }

  &__cta {
    &--ahref {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1.2rem;
      color: $themeGray;
      text-decoration: none;
      @include font-size(10);
      &::before {
        content: "";
        @include cta-round-button($accentRed, $white, 40);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
