.filter {
  display: flex;

  &-tags {
    display: none;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 3.75rem;
    grid: auto / 2fr max-content max-content;
    gap: toRem(50);

    &__container {
      display: flex;
      justify-content: space-between;
      padding-bottom: 2rem;
    }

    &--label {
      display: none;
      justify-content: flex-start;
      align-items: center;
      gap: 1.2rem;
      flex: 0 0 60%;
    }

    &__ulist {
      list-style: none;
      display: flex;
      gap: 15px;
      padding: 0;
      margin: 0;
      flex-wrap: wrap;
    }

    .bg-white {
      .filter-tags--litem {
        @include chips($chipGray, $themeGray);
        border: 1px solid $white;
        width: max-content;
        &.active,
        &:hover {
          @include chips($themeGray, $chipGray);
          border: 1px solid $chipGray;
        }
      }
    }

    &--litem {
      @include chips($chipGray, $themeGray);
      background-color: $themeGray;
      color: $chipGray;
      border: 1px solid $chipGray;
      width: max-content;
      &.active {
        background-color: $chipGray;
        color: $themeGray;
      }

      &:hover {
        background-color: $chipGray;
        color: $themeGray;
      }
    }

    &__mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      flex-direction: column;
    }

    &--select {
      display: flex;
      border: 1px solid $white;
      border-radius: 2rem;
      width: 100%;
      padding: 0.875rem 1.25rem;
      background: transparent;
      @include font-size(10);
      padding: 0.5em 3.5em 0.5em 1em;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: linear-gradient(45deg, transparent 50%, white 50%),
        linear-gradient(135deg, white 50%, transparent 50%);
      background-position:
        calc(100% - 20px) calc(1em + 2px),
        calc(100% - 15px) calc(1em + 2px),
        calc(100% - 2.5em) 0.5em;
      background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
      background-repeat: no-repeat;
    }

    &--seloptions {
      display: flex;
      position: absolute;
      top: 7rem;
      flex-direction: column;
      z-index: 99;
      margin: 0.2rem;
      padding: 0;
      list-style: none;
      width: 90%;
      border-radius: 1rem;
      background-color: darken($white, 5);

      li {
        margin: 0;
        padding: 1rem 0.2rem;
        text-indent: 15px;
        border-top: 1px solid darken($offWhite, 10);
        transition: all 0.15s ease-in;
        color: $themeGray;
        cursor: pointer;

        &:hover,
        &.is-selected {
          color: $white;
          background: $themeGray;
        }

        &[rel='hide'] {
          display: none;
        }
      }
    }

    &__clear {
      display: none;
      justify-content: center;
      align-items: center;
      gap: toRem(12);
      color: $themeGray;
      cursor: pointer;

      &:before {
        content: '\2715';
        width: toRem(10);
        color: $themeRed;
      }
    }

    &__sortby {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $gray900;
      gap: 0.3rem;
      flex: 0 0 100%;
    }

    &--sortsel {
      color: $themeGray;
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.298 4.674'%3E%3Cg id='Group_14158' data-name='Group 14158' transform='translate(11200.527 5234.533)'%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1036.17,6175.141l-3.649-3.607,1.054-1.066,2.594,2.564,2.595-2.564,1.054,1.066Z' transform='translate(-10160.708 -11405)' fill='%23df4661'/%3E%3C/g%3E%3C/svg%3E");
        content: '';
        border: 0;
        margin-left: 0.625em;
        width: 12px;
        height: 5px;
        display: inline-block;
        background-repeat: no-repeat;
        transition: 0.1s ease-in-out;
        color: $secondaryRed;
        margin-top: toRem(3);
      }
    }

    &__sorter {
      display: flex;
      flex-direction: column;
    }

    &--sortoptions {
      display: flex;
      position: absolute;
      flex-direction: column;
      margin-top: 2rem;
      gap: 0.4rem;
      border-radius: 0.3em;
      padding: 0;
      z-index: 99;
      background: $white;

      li {
        color: $themeGray;
        cursor: pointer;
        padding: 0.2rem 0.5rem;
        list-style: none;

        &:hover,
        &.is-selected {
          color: $white;
          background: $themeGray;
        }
      }
    }
    &__label {
      text-wrap: nowrap;
    }
  }

  &-container {
    z-index: 99;
    height: 100%;
    right: 0px;
    top: 0px;
    position: absolute;
    width: 100%;
    transition: all 0.5s ease;
    flex-direction: column;
    background: $white;
    overflow-y: auto;
    color: $themeGray;

    &.open-side {
      right: 0;
      transition: all 0.5s ease;
      visibility: visible;
    }

    &--overlay {
      cursor: pointer;
      opacity: 0.8;
      backdrop-filter: blur(24px);
      background-color: $darkGray;
      position: fixed;
      z-index: 11;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      -o-transition: all 1s;
      -webkit-transition: all 1s;
      transition: all 1s;
    }

    ul {
      list-style: none;
    }

    &__tags {
      padding: toRem(10) toRem(16);
      margin: 0;
    }

    &--licat {
      padding: toRem(10) 0;
      border-top: 1px solid $gray500;

      &--toggle {
        @include downCheveron($themeGray);
        height: toRem(8);
      }

      &--header {
        display: flex;
        justify-content: space-between;
        padding: 0 0 toRem(25) 0;
        align-items: center;
        margin: 0;
        color: $themeGray;
        @include font-size(12.5);

        span {
          cursor: pointer;

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__category-toggle {
      width: 100%;
      border: 0;
      padding: 0;
      background-color: transparent;
    }

    &--lisubcat {
      display: flex;
      justify-content: space-between;
      padding: 0.7rem 0;

      &:first-of-type {
        padding-top: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }

      label {
        @include font-size(10);
      }

      input[type='checkbox'] {
        accent-color: $themeGray;
        box-shadow: none;
        width: 25px;
        margin: 0;
        cursor: pointer;
        border: 2px solid $themeGray;

        &:checked ~ label {
          &:before {
            border: 2px solid $themeGray;
          }

          &:after {
            transform: rotate(-45deg) scale(1);
          }
        }

        &:focus + label::before {
          outline: 0;
        }
      }
    }

    &--subcat {
      padding: 0 0 toRem(25) 0;
    }

    &__header {
      display: flex;
      justify-content: flex-end;
      padding: toRem(50) toRem(16);
    }

    &--closebtn {
      cursor: pointer;
      border: none;
      background: transparent;
      width: 25px;
      height: 25px;
      padding: 0;

      svg {
        width: 25px;
      }
    }

    &__cta {
      display: flex;
      gap: 0.8rem;
      padding: toRem(25) toRem(16);
      position: fixed;
      bottom: 0;
      width: -webkit-fill-available;
      border-top: 1px solid $gray500;
      background: $white;

      button {
        display: flex;
        flex: 1;
        padding: 1rem;
        justify-content: center;
        align-items: center;
        border-radius: 28px;
        @include font-size(10);
      }
    }

    &--btnclear {
      background: $white 0% 0% no-repeat padding-box;
      color: $themeGray;
      border: 1px solid $themeGray;
      cursor: pointer;

      &:before {
        content: '';
      }
    }

    &--btnresults {
      background: $secondaryRed 0% 0% no-repeat padding-box;
      color: $white;
      border: 1px solid $secondaryRed;
      cursor: pointer;
    }
  }

  &__filsort {
    display: flex;
    gap: 0.9rem;
    padding: 0.5rem 0;

    &--ctafilter,
    &--ctasort {
      display: flex;
      flex: 1;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      border-radius: 28px;
      @include font-size(10);
    }

    &--ctafilter {
      background: $chipGray 0% 0% no-repeat padding-box;
      color: $themeGray;
      border: 1px solid $chipGray;
      cursor: pointer;
    }

    &--ctasort {
      background: $white 0% 0% no-repeat padding-box;
      color: $themeGray;
      border: 1px solid $themeGray;
      cursor: pointer;
    }
  }
}

.select-styled {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $themeGray;
  padding: 8px 15px;
  transition: all 0.2s ease-in;

  &:after {
    content: '';
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: $themeGray transparent transparent transparent;
    position: absolute;
    top: 16px;
    right: 10px;
  }

  &:hover {
    background-color: darken($themeGray, 2);
  }

  &:active,
  &.active {
    background-color: darken($themeGray, 5);

    &:after {
      top: 9px;
      border-color: transparent transparent $themeGray transparent;
    }
  }
}

.filters-open {
  overflow: hidden;
}

#filterContainer {
  height: 100vh;
  right: 0;
  top: 0;
  position: fixed;
  z-index: 20;
  width: 100%;
  display: none;
}
