.landpage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--title {
        @include font-size(16);
        font-weight: 300;
    }


    &--error,
    &--success {
        justify-content: center;
        align-items: center;
        display: none;


        @include font-size(12);
    }

    &--error {
        color: $themeRed;
    }

    &--success {
        color: $green;
    }


    &__form {
        display: flex;
        flex-direction: column;
        padding: 2.5rem 0;
        border-top: 1px solid $offWhite;
        align-items: center;
    }

    .input {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        padding-top: 10px;
        width: 100%;

        &+.input,
        &+.cbinput {
            margin-top: 10px
        }

        &+.cbinput {

            justify-content: flex-start;
            padding: 0.7rem 0;
            flex-direction: row;
            display: flex;
            align-items: center;
            gap: 1rem;
        }


    }


    input[type="checkbox"] {
        accent-color: $themeGray;
        box-shadow: none;
        width: 20px;
        height: 20px;
        margin: 0;
        cursor: pointer;
        border: 2px solid $themeGray;

        &:checked~label {
            &:before {
                border: 2px solid $themeGray;
            }

            &:after {
                transform: rotate(-45deg) scale(1);
            }
        }

        &:focus+label::before {
            outline: 0;
        }
    }

    .cbinput__label {
        color: $gray900;
        transition: all 0.3s;
        @include font-size(8.2);
    }

    .input_label {
        color: $gray900;
        position: absolute;
        top: 20px;
        transition: all 0.3s;
        @include font-size(8.2);
    }

    .input_field {
        border: 0;
        padding: .32rem 0;
        z-index: 1;
        background-color: transparent;
        border-bottom: 1px solid $offWhite;
        font: inherit;
        @include font-size(10);
        line-height: 26px;

        &:focus,
        &:valid {
            outline: 0;
            border-bottom-color: $themeGray;
        }

        &+.input_label {
            color: $gray900;
            transform: translateY(-25px);
        }
    }

    &__fields {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        padding-top: 2.5rem;
    }

    .disabled {
        opacity: .3;
        cursor: none;
        pointer-events: none;
    }

    &__savecta {
        display: flex;
        margin: 3rem 0;
        opacity: 1;
        cursor: pointer;

        &--href {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1.25rem;
            text-decoration: none;
            color: $themeGray;
            @include font-size(10);
            font-weight: 300;

            &::before {
                content: '';
                @include cta-round-button($accentRed, $white, 40);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
            }
        }
    }
}