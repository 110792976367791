@mixin transformRotateElement($degValue) {
  transform: rotate($degValue) !important
}

.transform_180deg {
  @include transformRotateElement(180deg)
}

.transform_90deg {
  @include transformRotateElement(90deg)
}

.transform_270deg {
  @include transformRotateElement(270deg)
}


.align-center {
  align-items: center;
}