.guideres{
    display: flex;
    flex-direction: column;
    padding: 2.5rem 1rem;
    gap: 3.75rem;

    &__header{
        &--label{
            @include font-size(19.38);
            font-weight: normal;
        }
    }

    
    &--largecard{
        flex: 0 0 47%;
        max-width: 47%;

        .guideres__card--detail{
            visibility: visible;
        }
    }

    &__container{
        display: flex;
        gap: 3.13rem;
        max-width: 100%;
        overflow: hidden;
        flex-direction: column;
    }

    .remove-before {
        &:before{
            background: none !important;
        }
    }
}