.service {
  display: flex;
  justify-content: center;
  align-items: center;

  .subsector-carousel {
    &__header {
      gap: 1.87rem;
      &__left {
        flex: 0 0 20%;
        h3 {
          @include font-size(24.38);
          font-weight: normal;
        }
      }

      &__right {
        align-items: flex-start;
        flex: 0 0 80%;
      }
    }
  }
}
