.actioncard {
  &__container {
    &--title {
      @include font-size(8.125);
    }
    &--heading {
      @include font-size(24.38);
      font-weight: normal;
    }

    &--desc {
      @include font-size(12.5);
    }

    &-default {
      padding: 5.93rem 1.87rem;
    }

    &-left {
      padding: 10rem;
    }
    &-center {
      padding: 10rem;
    }
  }
}
