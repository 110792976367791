.offlocard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p{
        margin: 0;
    }
    
    &--image {
        background: $white;
        overflow: hidden;
        min-width: 232px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 1.875rem;
        width: 100%;
        color: $secondaryGray;
        font-weight: 300;
    


        &__right{
            display: flex;
            flex-direction: column;
        }
      
        &--city{
            @include font-size(10);
            font-weight: 300;
        }

        &--addr{
            @include font-size(10);
            font-weight: 400;
            padding: 1.25rem 0 .94rem 0;
            li{
                list-style-type: none;
            }
        }

        &--phone{
            @include font-size(10);
        }

        &--cta {
            font-weight: 300;
            @include font-size(9);
            color: $themeRed;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1.25rem;
            margin-top: 2.5rem;

            &::before {
                @include rightLongArrow()
            }
        }
    }
}