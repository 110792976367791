.login{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8.5rem 7.18rem;

    &__left, &__right {
        flex: 0 0 35%;
    }

    &__right{
        padding: 2.5rem 4.125rem 2.5rem 2.5rem;
    }
}
