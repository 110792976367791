.searchkey {

    display: flex;
    flex-direction: column;

    &__updates {
        list-style: none;
    }

    &--title{
        display: flex;
    }

}