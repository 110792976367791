.wware{

    &__offices{
        padding: 5rem 3rem;
        &__header{
            width: 100%;
            flex-direction: row;
            &__left{
                flex: 0 0 50%;
                max-width: 50%;
            }
    
            &__right{
                display: flex;
                align-items: flex-end;
                flex: 0 0 50%;
                max-width: 50%;
            }
        }



        .office-glide__slides{
            flex-wrap: nowrap !important;
        }

        .glide__slides{
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .glide__slide {
            flex: 0 0 30% !important;
        }
    }
}