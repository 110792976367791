.testimonial-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $accentRed;
  height: 100%;

  &--imgwrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 342px;
    overflow: hidden;

    img {
      width: 100%;
      min-height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 1.38rem 1.68rem 2.4rem 1.68rem;
    @include font-size(10);
    background-color: $accentRed;
  }

  &__title {
    display: flex;
    flex-direction: column;
    @include font-size(24.37);
    font-weight: 300;
    color: $white;

    &--left {
      @include font-size(38);
      max-height: 3rem;
    }

    &--right {
      @include font-size(15.63);
      margin: 0;
      font-weight: normal;
    }
  }

  &__partner {
    display: flex;
    flex-direction: column;
    padding: 3.125rem 0;

    &--name {
      margin: 0;
      @include font-size(12.5);
      color: $white;
    }

    &--jobtitle {
      @include font-size(8.12);
      color: $white;
    }
  }

  &__arrows {
    display: flex;
    gap: 1.25rem;

    &--prev {
      @include prevCheveron();
    }

    &--next {
      @include nextArrow();
    }
  }
}
