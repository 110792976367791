.testimonial-card {
  display: flex;
  flex-direction: row-reverse;
  min-height: 37rem;
  height: 100%;

  &--imgwrapper {
    flex: 0 0 50%;
    overflow: hidden;
    img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    flex: 0 0 50%;
    justify-content: center;
    padding: toRem(60);
  }

  &__partner {
    padding: 3.125rem 0;
    &--name {
      @include font-size(12.5);
      color: $white;
    }
    &--jobtitle {
      @include font-size(8.12);
      color: $white;
    }
  }
}
