.officedet {
  padding: toRem(100) 0 0 0;

  &__header {
    flex-direction: row;

    &__content {
      display: flex;
      flex-direction: column;
      padding-left: 3rem;
      flex: 0 0 55%;
    }
  }

  &--title,
  &--para {
    padding-left: 0;
  }

  &__banner {
    img {
      max-height: 600px;
    }
  }

  &__actions {
    background: $white;
    gap: 0.94rem;
    padding: 0;
    padding-right: 3rem;
    flex: 0 0 45%;
    &__cta {
      background-color: $chipGray;
      border-radius: 1.75rem;
      padding: 0.88rem 1.4rem 0.88rem 1.4rem;
      flex: auto;

      &--href {
        flex-direction: row;
        gap: 0.625rem;

        img {
          margin: 0;
        }
      }
    }
  }

  &__content {
    flex-direction: row;
    padding: 5rem 7.2rem;
    justify-content: space-between;
  }

  &__about {
    flex: 0 0 40%;

    &--title {
      @include font-size(24.38);
      color: $secondaryGray;
    }
  }

  &__facilities {
    flex: 0 0 45%;
  }
}
