.testimonials {
  padding: 2rem 5rem;

  $xxlarge: 1920px;

  @include breakpoint(tablet) {
    padding: 3.125rem 10.31rem;
  }

  &__header {
    &--title {
      @include font-size(8.1);
    }
  }

  &-glide {
    display: flex;
    margin: 2.57rem 0;

    .glide__arrows {
      width: max-content;
      left: toRem(66);
      height: max-content;
      bottom: toRem(140);
    }

    .glide__arrow {
      &--next {
        margin: 0 3rem;
      }
    }
  }
}
