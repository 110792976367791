.guideres__card {

    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    max-width: 100%;
    background: $themeRed;
    color: $white;
    font-weight: 200;
    padding: 3.75rem 1.875rem;

    @include bgimageconfig();
    @include background-shade();
   
    .sitecore-image{
        display: none;
        width: 100%;

    }

    &--tag{
    @include font-size(8.15);
    z-index: 2;
    }

    &--title{
        display: flex;
        align-items: center;
        @include font-size(24.38);
        font-weight: normal;
        gap: 1.6rem;
        z-index: 2;
        &--divider {
            width: 1px;
            height: .8rem;
            background-color: $themeRed;
        }
    }

    &--detail {
        z-index: 2;
        display: flex;
        @include font-size(10);
        font-weight: 400;
        max-width: 100%;
        margin: 3.75rem 0 2.5rem 0;
        visibility: hidden;
        max-height: auto;
        overflow: hidden;

    }

    &__cta {
        z-index: 2;
        @include font-size(10);
        font-weight: 300;
        color: $accentRed;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        gap: 1.25rem;
        padding:0;

        
        &:before {
            @include leftLongArrow()
        }

        &--href {
            color: $white;
        }
    }

    .download-arrow{
        &:before {
            @include downShortArrow(20, 20)
        }
    }

}