.swiper {
  width: 100%;

  .swiper-slide {
    height: auto;

    & > div {
      height: 100%;
    }
  }

  &-wrapper {
    padding-bottom: toRem(60);
  }
}

.swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
  bottom: 0 !important;
  left: 0 !important;
}
