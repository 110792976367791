.officedet {
  display: flex;
  flex-direction: column;
  padding: toRem(114) 0 0 0;

  &__header {
    display: flex;
    flex-direction: column;

    &__content {
      display: flex;
      flex-direction: column;
      padding: 0 0 3.15rem 0;
    }
  }

  &__actions {
    display: flex;
    list-style: none;
    background-color: $chipGray;
    margin: 0;
    padding: 1.56rem 0;
    width: 100%;
    justify-content: center;
    align-self: center;

    &__cta {
      display: flex;
      flex: 0 0 22%;
      justify-content: center;
      align-self: center;
      &--href {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @include font-size(8.12);
        border-right: 1px solid $offWhite;

        img {
          height: 22px;
          margin-bottom: 0.63rem;
        }
      }
    }
  }

  &--title {
    display: flex;
    @include font-size(24.38);
    font-weight: 300;
    padding: 0 1rem;
  }

  &--para {
    @include font-size(12.5);
    font-weight: 200;
    padding: 0 1rem;
  }

  &__banner {
    width: 100%;
    img {
      width: 100%;
      max-height: 600px;
      object-fit: cover;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 3.75rem 1rem 5rem 1rem;
    background: $backgroundGray;

    &--addr {
      margin: 0;
    }
  }

  &__about {
    display: flex;
    flex-direction: column;

    &--title {
      @include font-size(19.3);
      color: $secondaryGray;
      margin: 0;
      padding: 1.25rem 0;
    }

    &--para {
      @include font-size(19.3);
      @include font-size(12.5);
    }
    &--address {
      @include font-size(12.5);
      display: flex;
      text-decoration: none;
      flex-direction: column;
      list-style: none;
      padding: 0;
    }

    &__cta {
      display: flex;
      flex-direction: column;
      padding: 2.5rem 0 2.5rem 0;
      &--ahref {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.2rem;
        color: $themeGray;
        text-decoration: none;
        @include font-size(10);
        &::before {
          content: '';
          @include cta-round-button($accentRed, $white, 40);
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
        }
      }
    }

    &__contact {
      display: flex;
      flex-direction: column;
      &--row {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1.56rem 0;
        border-bottom: 1px solid $offWhite;
      }

      &--key,
      &--value {
        @include font-size(10);
      }

      &--key {
        color: $themeGray;
        flex: 0 0 30%;
      }

      &--value {
        color: $secondaryRed;
        flex: 0 0 70%;
      }
    }
  }

  &__facilities {
    display: flex;
    flex-direction: column;
    padding: 5rem 0 0;

    &--header {
      display: flex;
      @include font-size(12.5);
      font-weight: 300;
      padding: 0.56rem 0 0.56rem 0;
      color: $secondaryGray;
    }

    &__list {
      display: flex;
      flex-direction: column;
      padding: 0;
      gap: 1.25rem;

      li {
        list-style: none;
        font-weight: 200;
        @include font-size(12.5);

        &::before {
          content: '\2212';
          width: 100%;
          color: $themeRed;
          padding-right: 0.5rem;
        }
      }
    }
  }
}
