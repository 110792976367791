.articlecard {
  flex: 0 0 32%;
  max-width: 32%;

  &--large {
    flex: 0 0 45%;
  }

  &--fullwidth {
    grid-column-start: 1;
    grid-column-end: 4;
    aspect-ratio: 671/250;

    .articlecard {
      &__label {
        padding: toRem(50) toRem(60) 0 toRem(50);
      }

      &__detail {
        padding: toRem(15) toRem(50) toRem(112) toRem(50);
      }

      &__cta {
        a {
          padding: toRem(60) toRem(50);
        }
      }
    }
  }

  &--largeimgwrap,
  &--imgwrap {
    width: 100%;
  }

  &--largeimgwrap {
    height: auto;

    @include breakpoint($device-xxlarge) {
      min-width: 886px;
    }

    @include breakpoint($device-xlarge) {
      min-width: 640px;
    }

    width: 100%;
  }

  &--imgwrap {
    min-width: 414px;
  }

  &__detail {
    @include font-size(19.38);
    font-weight: normal;
  }
}
