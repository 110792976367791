.testimonials {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1rem;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 0.63rem;

    &--title,
    &--subtitle {
      margin: 0;
      font-weight: 300;
    }

    &--title {
      @include font-size(8.1);
    }
  }

  &-glide {
    display: flex;
    margin: 2.57rem 0;
    flex: 0 0 100%;
    cursor: none !important;

    .glide__arrows {
      width: max-content;
      left: toRem(30);
      bottom: toRem(120);
      height: 20px;
      top: unset;

      .disable {
        cursor: not-allowed;
        pointer-events: none;
        color: $red;
      }
    }

    .glide__arrow {
      background: transparent;

      &--prev {
        @include prevArrow();
      }

      &--next {
        @include nextArrow();
        margin: 0 3rem;
      }
    }

    .glide__track {
      width: 100%;
    }

    .glide__slides {
      @include scrollbar($borderGray, $offWhite);
      display: flex;
      list-style: none;
      justify-content: flex-start;
      padding: 0;
      margin: 0;
      padding-bottom: 3.75rem;
      gap: 0;
      cursor: auto;
    }

    .glide__slide {
      margin-left: 0 !important;
      width: 0;
    }
  }

  &__litem {
    width: 100% !important;
    margin: 0;
  }
}
