.heroarticle{
    padding: 3rem 2rem;

    &__container{
        gap: 2rem;
    }
    &__about {
        flex: 0 0 90%;
        max-width: 30%;
        padding-bottom: 6rem;

        @include breakpoint($device-xxlarge) {
            max-width: 30%;
        }

        &--title {
            @include font-size(38.125);
            font-weight: 600;
        }
        &--subtext {
            @include font-size(12.5);
        }
    }

    &__article {
        flex: 0 0 80%;
        max-width: 80%;
        @include breakpoint($device-xlarge) {
            flex: 0 0 90%;
            max-width: 90%;
          }


        padding: 14rem 1rem 2.5rem 0;
        &--title {
            width: 40%;
        }
    }

    &__txtarrow {
       
        display: flex;
        &--label {
            @include font-size(10); 
        }

        &--arrow{
            @include font-size(20); 
            color: $white;
        }



        @include breakpoint($device-xxlarge) {
            flex: 0 0 5%;
          }
    }

    &__video{
        position: absolute;
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;

        iframe{
            position: relative;
            top: 0px;
            left: 0;
            width: 100%;
            border: 0;
            height: 900px;
        }    
    }
}