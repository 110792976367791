.heroheader {
    display: flex;
    flex-direction: column;

    @include bgimageconfig();
    @include background-shade();

    &__content {
        display: flex;
        flex-direction: column;
        z-index: 2;
        padding: 16.87rem 1rem 3.125rem 1rem;
        @include bgimageconfig();

        &--title {
            @include font-size(30.63);
            font-weight: normal;
            color: $white;
        }

        &--detail {
            @include font-size(12.5);
            font-weight: 200;
            color: $white;
        }
    }

    &__actions {
        display: flex;
        list-style: none;
        z-index: 2;
        background-color: $white;
        margin: 0;
        padding: 1.56rem 0;
        width: 100%;
        justify-content: center;
        align-self: center;
    }

    &__cta {
        display: flex;
        flex: 0 0 22%;
        justify-content: center;
        align-self: center;

        &--services,
        &--contacts,
        &--locations,
        &--resources {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @include font-size(8.12);
            border-right: 1px solid $offWhite;

            img{
                height: 22px;
                margin-bottom: .63rem
            }
        }

    
    }

   


}