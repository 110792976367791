.filter {
  &-tags {
    display: grid;
    padding-bottom: 0;

    &--label {
      display: flex;
    }
    &__clear {
      display: flex;
    }
    &__mobile {
      display: none;
    }
  }

  &-container {
    visibility: hidden;
    padding: 0 toRem(50);
    right: 0;
    bottom: 0;
    width: toRem(500);
    max-height: 100%;

    &__header {
      padding: 3.12rem 0;
    }

    &__cta {
      gap: toRem(14);
      background: $white;
      width: toRem(500);
      right: 0;
      padding: toRem(25) toRem(50);
    }

    &__tags {
      padding: 0 0 toRem(109) 0;
    }

    &--subcat {
      padding: 0 0 toRem(25) toRem(15);
    }
  }

  &__filsort {
    display: none;
  }
}
