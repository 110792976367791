.interactmap {
  display: none;

  &--subsc {
    font-size: 0.83em;
    line-height: 0.5em;
    vertical-align: baseline;
    position: relative;
    top: -0.4em;
  }

  &__header {
    &__title {
      p:nth-child(2) {
        @include font-size(19.38);
        font-weight: normal;
      }
    }
  }
}
