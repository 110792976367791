.articlecard {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  position: relative;
  padding-bottom: 68px;

  &--fullwidth {
    flex: 0 0 100%;
    aspect-ratio: 436 / 597;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    color: $white;

    &:hover {
      img {
        transform: scale(1.25);
      }
    }

    .articlecard {
      &__label {
        padding: toRem(40) toRem(20) 0;

        p {
          margin: 0;
        }
        &--divider {
          background-color: $white;
        }
      }
      &__detail {
        min-height: auto;
        padding: toRem(15) toRem(20) toRem(80) toRem(20);
      }
      &__cta,
      &__detail {
        margin: 0;
      }

      &__cta {
        a {
          padding: toRem(40) toRem(20);
        }
      }

      &__img-link {
        z-index: -1;
      }
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
      bottom: 0;
      transition: transform 0.5s ease;
      z-index: -1;
    }
  }

  &__container {
    display: flex;
    width: 100%;
    flex-direction: column;
    z-index: 2;

    color: $white;
  }

  &--large {
    flex: 0 0 100%;
  }

  p {
    margin: 0;
  }

  &--cross {
    @include cta-cross-round(100);
    position: absolute;
    margin-left: -1rem;
    margin-top: -1rem;
    z-index: 9;
  }

  &--largeimgwrap,
  &--imgwrap {
    min-width: 328px;
    max-width: 25%;
    overflow: hidden;
    img {
      transition: transform 0.5s ease;
      width: 100%;
    }

    &:hover img {
      transform: scale(1.25);
    }
  }

  &__img-link {
    width: 100%;
    z-index: 1;
    overflow: hidden;
    display: flex;
  }

  &__label {
    display: flex;
    align-items: center;

    @include font-size(8.12);
    font-weight: 300;
    gap: 1.6rem;
    padding: 1.25rem 0 0;

    &--divider {
      width: 1px;
      height: 0.8rem;
      background-color: $themeGray;
    }
  }

  &__detail {
    display: flex;
    padding-top: toRem(15);

    h3 {
      margin: 0;
      @extend .h4;
    }
  }

  &__cta {
    @include font-size(10);
    font-weight: 300;
    color: $accentRed;
    display: flex;
    position: absolute;
    height: 100%;
    width: 100%;

    a {
      color: $accentRed;
      justify-content: flex-start;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: flex-end;
      gap: 1.25rem;
      padding: 0;

      &:before {
        @include rightLongArrow();
      }
    }
  }

  &__placeholder {
    background-color: $gray200;
  }
}
