.interactmap {
  display: flex;
  flex-direction: column;
  padding: 2rem 6.18rem;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4.125rem;

    &__title {
      display: flex;
      flex-direction: column;

      p:first-child {
        @include font-size(8);
      }

      p:nth-child(2) {
        @include font-size(15.63);
        font-weight: normal;
      }
    }

    &__viewall {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &--btn {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.2rem;
        color: $themeGray;
        text-decoration: none;
        @include font-size(10);
        &::before {
          content: '';
          @include cta-round-button($accentRed, $white, 40);
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
        }
      }
    }
  }

  svg {
    position: relative;

    transform: translate(0%, 0%);
  }

  .country {
    fill: #ccc;
    stroke: $white;
  }

  // .Antarctica {
  //   display: none;
  // }

  .tooltip {
    position: absolute;
    background-color: $white;
    border: solid 1px $themeGray;
    border-radius: 1rem;
    padding: 0.2rem toRem(20);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &--supsc {
    padding: 0 0.2rem;
    cursor: pointer;
    font-size: 10px;
    margin: toRem(-10) 0 0 0;
  }

  .circle {
    padding: 1rem;
  }

  .circle:hover {
    stroke: $black;
    stroke-width: 4px;
  }
}
