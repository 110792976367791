// =======================================
//    #UTILITY - FONTS
// =======================================

// =============================================================================
// Font Face
// =============================================================================
@mixin font-face(
  $name,
  $path,
  $weight: null,
  $style: null,
  $exts: otf ttf woff woff2
) {
  $src: null;

  $formats: (
    otf: 'opentype',
    ttf: 'truetype',
  );

  @each $ext in $exts {
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append(
      $src,
      url(quote($path + '.' + $ext)) format(quote($format)),
      comma
    );
  }

  @font-face {
    font-display: swap;
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}

@include font-face(
  'Avenir',
  '#utility/avenir/AvenirLTStd-Light',
  100,
  normal,
  'otf'
);
@include font-face(
  'Avenir',
  '#utility/avenir/AvenirLTStd-Book',
  200,
  normal,
  'otf'
);
@include font-face(
  'Avenir',
  '#utility/avenir/AvenirLTStd-Roman',
  300,
  normal,
  'otf'
);
@include font-face(
  'Avenir',
  '#utility/avenir/Avenir-Regular',
  400,
  medium,
  'ttf'
);
@include font-face(
  'AvenirRegular',
  '#utility/avenir/Avenir-Regular',
  500,
  normal,
  'ttf'
);
@include font-face(
  'Avenir',
  '#utility/avenir/AvenirLTStd-Black',
  500,
  bold,
  'otf'
);

$systemFont: 'Avenir';

$fallbackSans: $systemFont;

$sans: 'Avenir', sans-serif, $fallbackSans;

$altSans: 'Avenir', sans-serif, $fallbackSans;

$mono: 'Avenir', sans-serif;

$avenir: $systemFont;

body {
  font-family: $systemFont;
  margin: 0;
}

// ========================================================================= //
//  Web Fonts
//
//
//  FOR REFERENCE: Font-weights by numeric value / name.
//  Side note: it is recommended that we to use the numeric value here to help clarify exactly which weight is getting referenced to.
//    100 = thin
//    200 = extra-light
//    300 = light
//    400 = normal, book
//    500 = medium
//    600 = demi-bold / semi-bold
//    700 = bold
//    800 = heavy / extra-bold / heavy
//    900 = black / extra-black / ultra-black
//
// ========================================================================= //
