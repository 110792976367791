%button,
.button {
  align-items: center;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 5rem;
  display: inline-flex;
  font-weight: 500;
  justify-content: flex-start;
  line-height: 1;
  padding: 1.2rem 3rem;
  cursor: pointer;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &[disabled] {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
  }

  .icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    margin-right: 1rem;

    svg {
      height: auto;
      max-height: 2rem;
      max-width: 2rem;
      width: 2rem;

      path {
        stroke: currentColor;
      }
    }
  }

  .arrow {
    display: none;

    @include breakpoint($desktop) {
      align-items: center;
      align-self: center;
      display: inline-flex;
      justify-content: center;
      margin-left: 1rem;
    }

    svg {
      height: auto;
      max-height: 1.4rem;
      max-width: 1.4rem;
      stroke: currentColor;
      width: 1rem;
    }
  }

  &__text {
    @include font-size(14, 14);
    font-weight: bold;
    white-space: nowrap;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      margin-bottom: 3px;
    }
  }

  &--primary {
    background-color: $brandGreen;
    color: $white;
    transition:
      background-color 0.3s ease-in-out,
      border-color 0.3s ease-in-out;

    &:focus {
      background-color: $darkGreen;
      color: $white;
    }

    &:hover {
      background-color: $lightGreen;
      color: $white;
    }

    &:active {
      background-color: $darkerGreen;
      color: $white;
    }

    &[disabled] {
      background-color: $mediumGreen;
      opacity: 0.8 !important;
    }
  }

  &--secondary {
    background-color: $white;
    border-color: $darkBlue;
    color: $darkBlue;
    transition:
      background-color 0.3s ease-in-out,
      border-color 0.3s ease-in-out;

    &:focus,
    &:hover {
      border-color: $gray100;
    }
  }

  &--white {
    background-color: $white;
    border: 2px solid $white;
    color: $darkerGray;

    &:focus,
    &:hover {
      background-color: $gray100;
      color: $darkBlue;
    }
  }

  &--transparent {
    background-color: transparent;
    border-color: $white;
    color: $white;

    &:hover {
      background-color: rgba($color: $white, $alpha: 0.2);
    }

    &:active {
      background-color: rgba($color: $white, $alpha: 0.5);
    }

    &[disabled] {
      opacity: 0.2 !important;
    }
  }

  &--link {
    background-color: transparent;
    border-radius: 0;
    color: $brandGreen;
    padding: 0;

    &[disabled] {
      color: $darkerGray;
    }
  }

  &--green-border {
    background-color: transparent;
    border: 3px solid $brandGreen;
    border-radius: 0;
    color: $darkBlue;
  }

  &--round {
    border-radius: 4rem;
    height: 4rem;
    justify-content: center;
    padding: 0;
    width: 4rem;

    .icon {
      margin: 0;
    }
  }

  &--utility {
    border: 1px solid $white;
    border-radius: 0;
    color: $white;
    padding: 0.5rem 1.5rem;

    &:focus,
    &:hover {
      color: $white;
      text-decoration: none;
    }

    .button__text {
      @include font-size(12, 12);
      font-weight: normal;
    }
  }

  &--arrow {
    .arrow {
      margin-top: -1px;
      transform: translateX(0);
      transition: all 0.3s ease-in-out;
    }

    &:hover {
      .arrow {
        transform: translateX(5px);
      }
    }
  }

  &--small {
    padding: 1rem;

    .button__text {
      @include font-size(12, 12);
    }

    span.icon {
      margin-right: 0.5rem;

      svg {
        max-height: $spacing-unit;
        max-width: $spacing-unit;
      }
    }
  }

  &--large {
    padding: 1rem 4.5rem;

    .button__text {
      @include font-size(22, 22);
    }
  }

  &--orange-outline {
    border: 2px solid $orange;
    border-radius: 0px;
    color: $orange;
    display: block;
    font-family: $sans;
    padding: 2rem;

    .button__text {
      @include font-size(16, 16);
      letter-spacing: 2px;

      @include breakpoint($desktop) {
        @include font-size(22, 22);
        letter-spacing: 6px;
      }
    }
  }

  &--green-border-round {
    background-color: transparent;
    border: 2px solid $brandGreen;
    color: $brandGreen;
  }

  &--green-text {
    color: $brandGreen;
  }

  &--download {
    .button__text {
      order: 1;
    }

    .icon {
      margin-left: 1rem;
      margin-right: 0;
      order: 2;

      svg {
        stroke-width: 2px;
      }
    }
  }

  &--back {
    &:hover,
    &:focus {
      .arrow {
        transform: translateX(-5px);
      }
    }

    .arrow {
      margin-left: 0;
      margin-right: 1rem;
      order: 1;
    }

    .button__text {
      order: 2;
    }
  }

  &--tooltip {
    align-items: center;
    background-color: $darkBlue;
    border: 0;
    border-radius: 50%;
    color: $mediumGreen;
    cursor: pointer;
    display: inline-flex;
    font-size: 1.2rem;
    font-weight: bold;
    height: 20px;
    justify-content: center;
    margin-left: 1rem;
    overflow: hidden;
    width: 20px;
  }

  &__exclaim {
    margin-right: 1em;

    svg {
      animation: squish 1.2s ease-in-out 5;
      fill: currentColor;
      transform-origin: 50% 50%;
    }

    @keyframes squish {
      20% {
        transform: scaleY(0.6);
      }

      40% {
        transform: scale(1.4);
      }

      50% {
        transform: scale(1);
      }

      79% {
        transform: rotate(0);
      }

      80% {
        transform: rotate(-10deg);
      }

      90% {
        transform: rotate(10deg);
      }
    }
  }

  &-status {
    &--moderate {
      background-color: $orange;
      border-color: $orange;
      color: $darkBlue;

      &:focus,
      &:hover {
        background-color: darken($orange, 10%);
        color: $white;
      }
    }

    &--high {
      background-color: $brightred;
      border-color: $brightred;
      color: $white;

      &:focus,
      &:hover {
        background-color: darken($brightred, 10%);
        color: $white;
      }
    }
  }
}

@keyframes cartbounce {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(-2px);
  }

  80% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

button {
  position: relative;
  cursor: pointer;

  &.cart {
    @include font-size(14, 14);
    background-color: $lightGreen;
    border: 0px;
    border-radius: 5rem;
    color: $darkBlue;
    overflow: hidden;
    padding: 1rem $spacing-unit;
    text-align: left;
    white-space: nowrap;

    &:hover {
      animation: cartbounce 2s infinite;
    }

    span.add {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      padding: 0 $spacing-unit--l;
      position: relative;
      transition: 0.4s transform;
    }

    span.plus {
      align-items: center;
      display: flex;
      height: 100%;
      left: -0.5rem;
      position: absolute;
      top: -0.05rem;

      svg {
        color: $white;
        height: 16px;
      }
    }

    .cart {
      @include visually-hidden;
      margin-right: 6px;
      padding: 0 $spacing-unit--l;
      transform: translateX(-100px);
      transition: 0.4s transform;
      transition-delay: 0.4s;

      svg {
        height: 20px;
      }
    }

    .item-added {
      @include visually-hidden;
      padding: 0 $spacing-unit--l;
      transform: translateX(-100px);
      transition: 0.4s transform;
      transition-delay: 1.4s;

      span.plus {
        align-items: center;
        display: flex;
        left: 1rem;

        svg {
          background-color: $white;
          border-radius: 5rem;
          color: $darkBlue;
          height: 16px;
          padding: 2px;
          width: 16px;
        }
      }
    }

    &.added-to-cart {
      span.add {
        transform: translateX(200px);
      }

      span.cart,
      span.item-added {
        align-items: center;
        background-color: $lightGreen;
        clip: auto;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        opacity: 1;
        top: 0;
        transform: translateX(0px);
        width: 100%;
      }

      span.item-added {
        align-items: center;
        background-color: $lightGreen;
        clip: auto;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        opacity: 1;
        top: 0;
        width: 100%;
      }
    }

    // The node that says "You have already added this item to your cart. It should be hidden by default unless
    // there is reason to think otherwise.
    & + div.already-in-cart {
      color: $orange; // stolen from inline style in _AddToCart.cshtml
      display: none;
    }

    // Rules for when a product has already been added to the cart
    &.already-added {
      // Don't show the button if it's already added
      display: none;

      // If it is known that the item is already added, then the notice should be shown.
      & + div.already-in-cart {
        display: block;

        // There is a also a rule in this file which that says <p> should be display: none, but that causes the Already In Cart message
        // to always be invisible when it should be shown.
        p {
          display: block;
        }
      }
    }
  }

  + .button {
    margin-left: $spacing-unit;
  }
}

@mixin cta-round-button($bgColor, $color, $size: 30) {
  flex-shrink: 0;
  background: $bgColor;
  color: $color;
  opacity: 1;
  width: #{$size}px;
  height: #{$size}px;
  border-radius: 2rem;
  border: 1px solid $bgColor;
  padding: 0;
  cursor: pointer;
  transition: transform 0.7s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: #{$size/1.2}px;
  background-repeat: no-repeat;
  background-position: center;

  svg {
    width: #{$size/2.5}px;
    fill: $color;
    color: $color;

    line {
      stroke: $color;
    }
  }
}

@mixin cta-plus-round($size: 30) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg id='Group_14236' data-name='Group 14236' transform='translate(8974.336 2430.336)'%3E%3Cline id='Line_1186' data-name='Line 1186' y2='16' transform='translate(-8966.336 -2430.336)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3Cline id='Line_1187' data-name='Line 1187' x1='16' transform='translate(-8974.336 -2422.336)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  width: #{$size}px;
  height: #{$size}px;
}

@mixin cta-email($size: 30) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22.705' height='15.534' viewBox='0 0 22.705 15.534'%3E%3Cg id='Orion_envelope_3_' data-name='Orion_envelope (3)' transform='translate(-0.94 -10.94)'%3E%3Cpath id='layer2' d='M2,12l10.293,9.4L22.586,12' transform='translate(0 0)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3Cpath id='layer1' d='M2,12H22.586V25.724H2Z' transform='translate(0 0)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  width: #{$size}px;
  height: 18px;
}

@mixin cta-phone($size: 30) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.449' height='21.448' viewBox='0 0 21.449 21.448'%3E%3Cpath id='layer1' d='M20.935,17.008l-3.461-2.263a1.6,1.6,0,0,0-2.163.433c-.8.965-1.764,2.562-5.391-1.065s-2.063-4.626-1.1-5.424a1.6,1.6,0,0,0,.433-2.163L6.992,3.065c-.3-.433-.7-1.131-1.631-1A4.544,4.544,0,0,0,2,6.559c0,2.995,2.363,6.656,5.591,9.883S14.479,22,17.441,22a4.824,4.824,0,0,0,4.492-3.328C22.033,18.007,21.368,17.308,20.935,17.008Z' transform='translate(-1.25 -1.302)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3C/svg%3E%0A");
  @include commonProperties();
  width: #{$size}px;
  height: #{$size}px;
}

@mixin cta-save-white($size: 20) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cg id='Group_14236' data-name='Group 14236' transform='translate(8974.336 2430.336)'%3E%3Cline id='Line_1186' data-name='Line 1186' y2='16' transform='translate(-8966.336 -2430.336)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3Cline id='Line_1187' data-name='Line 1187' x1='16' transform='translate(-8974.336 -2422.336)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  width: #{$size}px;
  height: #{$size}px;
}

@mixin cta-cross-round-white($size: 30) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.693' height='12.245' viewBox='0 0 12.693 12.245'%3E%3Cg id='Group_14519' data-name='Group 14519' transform='translate(-4.838 5.899) rotate(-45)'%3E%3Cline id='Line_1186' data-name='Line 1186' y2='15.818' transform='translate(7.592 0)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3Cline id='Line_1187' data-name='Line 1187' x1='15.818' transform='translate(0 8.225)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  width: #{$size}px;
  height: #{$size}px;
}

@mixin cta-plus($size: 20) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Group_14342' data-name='Group 14342' transform='translate(-1026 -1655.209)'%3E%3Cline id='Line_1186' data-name='Line 1186' y2='18' transform='translate(1035 1655.209)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3Cline id='Line_1187' data-name='Line 1187' x1='18' transform='translate(1026 1664.209)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  width: #{$size}px;
  height: #{$size}px;
  @include commonProperties();
}

@mixin cta-white-cross-round($size: 30) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Crect id='Rectangle_6237' data-name='Rectangle 6237' width='40' height='40' rx='20' fill='%23fff'/%3E%3Cg id='Group_14242' data-name='Group 14242' transform='translate(8986.336 2442.336)'%3E%3Cline id='Line_1186' data-name='Line 1186' y2='16' transform='translate(-8960.679 -2427.993) rotate(45)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3Cline id='Line_1187' data-name='Line 1187' x1='16' transform='translate(-8971.993 -2427.993) rotate(45)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");
  @include commonProperties();
  width: #{$size}px;
  height: #{$size}px;
}

@mixin cta-cross-round($size: 30) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg id='Group_14569' data-name='Group 14569' transform='translate(3870 -254) rotate(90)'%3E%3Crect id='Rectangle_6371' data-name='Rectangle 6371' width='40' height='40' rx='20' transform='translate(254 3830)' fill='%23ce5163'/%3E%3Cg id='Group_14367' data-name='Group 14367' transform='translate(-8846.772 5603.402) rotate(154)'%3E%3Cline id='Line_1186' data-name='Line 1186' y2='16' transform='translate(-8966.336 -2430.336)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3Cline id='Line_1187' data-name='Line 1187' x1='16' transform='translate(-8974.336 -2422.336)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  width: #{$size}px;
  height: #{$size}px;
}

@mixin profic-account($size: 30) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.002' height='16.303' viewBox='0 0 20.002 16.303'%3E%3Cg id='Orion_team' transform='translate(-1.25 -7.248)'%3E%3Cpath id='layer2' d='M30,17.25a11.317,11.317,0,0,0,1.85-.432c.956-.339.925-.833.925-1.11v-.617m-.247-6.476A2.745,2.745,0,0,1,34.317,8a2.607,2.607,0,0,1,2.775,2.652v1.789a3.515,3.515,0,0,1-1.233,2.652v.586c0,.37-.031,1.018,1.819,1.388A2.775,2.775,0,0,1,39.868,19.1v1.233H36.784' transform='translate(-19.366)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath id='layer1' d='M12.916,19.071c-2.313-.308-2.282-1.141-2.282-1.573v-.709a4.424,4.424,0,0,0,1.233-3.361V11.269A2.974,2.974,0,0,0,8.784,8,2.974,2.974,0,0,0,5.7,11.269v2.159a4.5,4.5,0,0,0,1.233,3.392v.74c0,.463.031,1.2-2.282,1.511A3.093,3.093,0,0,0,2,21.568V22.8H15.568V21.568S15.568,19.472,12.916,19.071Z' transform='translate(0 -0.001)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");
  @include commonProperties();
  width: #{$size}px;
  height: #{$size}px;
}

@mixin profic-people($size: 30) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20.002' height='16.303' viewBox='0 0 20.002 16.303'%3E%3Cg id='Orion_team' transform='translate(-1.25 -7.248)'%3E%3Cpath id='layer2' d='M30,17.25a11.317,11.317,0,0,0,1.85-.432c.956-.339.925-.833.925-1.11v-.617m-.247-6.476A2.745,2.745,0,0,1,34.317,8a2.607,2.607,0,0,1,2.775,2.652v1.789a3.515,3.515,0,0,1-1.233,2.652v.586c0,.37-.031,1.018,1.819,1.388A2.775,2.775,0,0,1,39.868,19.1v1.233H36.784' transform='translate(-19.366)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath id='layer1' d='M12.916,19.071c-2.313-.308-2.282-1.141-2.282-1.573v-.709a4.424,4.424,0,0,0,1.233-3.361V11.269A2.974,2.974,0,0,0,8.784,8,2.974,2.974,0,0,0,5.7,11.269v2.159a4.5,4.5,0,0,0,1.233,3.392v.74c0,.463.031,1.2-2.282,1.511A3.093,3.093,0,0,0,2,21.568V22.8H15.568V21.568S15.568,19.472,12.916,19.071Z' transform='translate(0 -0.001)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");
  @include commonProperties();
  width: #{$size}px;
  height: #{$size}px;
}

@mixin profic-content($size: 30) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.997' height='17.831' viewBox='0 0 18.997 17.831'%3E%3Cg id='Orion_bookmark-ribbon' transform='translate(-1.25 -3.25)'%3E%3Cpath id='layer2' d='M8.416,20.831a2.333,2.333,0,0,1,2.333,2.333V10.333A2.333,2.333,0,0,0,8.416,8H2V20.831ZM17.164,8H19.5V20.831H13.081a2.333,2.333,0,0,0-2.333,2.333' transform='translate(0 -2.834)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath id='layer2-2' data-name='layer2' d='M32,10.333A2.333,2.333,0,0,1,34.333,8h.583' transform='translate(-21.251 -2.834)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath id='layer1' d='M45.5,10.416,43.75,9.249,42,10.416V4h3.5Z' transform='translate(-28.335)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");
  @include commonProperties();
  width: #{$size}px;
  height: #{$size}px;
}

@mixin profic-edit($size: 30) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.38' height='18.38' viewBox='0 0 18.38 18.38'%3E%3Cg id='Orion_edit_2_' data-name='Orion_edit (2)' transform='translate(-1.65 -1.65)'%3E%3Cpath id='layer1' d='M34.483,2.4,27.041,9.814,25.9,12.58l2.766-1.169L36.08,4Z' transform='translate(-16.799)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath id='layer2' d='M11.525,11.6H2.4V25.857H16.657V16.733' transform='translate(0 -6.577)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");
  @include commonProperties();
  width: #{$size}px;
  height: #{$size}px;
}

@mixin profic-settings($size: 30) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.5' height='15.5' viewBox='0 0 17.5 15.5'%3E%3Cg id='Group_16027' data-name='Group 16027' transform='translate(-1121.25 -317.25)'%3E%3Cpath id='layer2' d='M17.178,6v5' transform='translate(1112.822 312)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath id='layer2-2' data-name='layer2' d='M17.178,6V16' transform='translate(1118.822 316)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath id='layer2-3' data-name='layer2' d='M17.178,16V6' transform='translate(1106.822 312)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath id='layer2-4' data-name='layer2' d='M17.178,6v5' transform='translate(1112.822 321)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Ccircle id='layer1' cx='2' cy='2' r='2' transform='translate(1128 323)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Ccircle id='layer1-2' data-name='layer1' cx='2' cy='2' r='2' transform='translate(1134 318)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Ccircle id='layer1-3' data-name='layer1' cx='2' cy='2' r='2' transform='translate(1122 328)' fill='none' stroke='%23df4661' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  width: #{$size}px;
  height: #{$size}px;
}

@mixin profic-signout($size: 30) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.007' height='16.93' viewBox='0 0 19.007 16.93'%3E%3Cg id='Orion_export' transform='translate(-1.25 -5.25)'%3E%3Cpath id='layer2' d='M11.5,10.748V6H2V21.43h9.5V16.682' fill='none' stroke='%234a4948' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cpath id='layer1' d='M14,24.264H27.946m-3.857,3.264,3.857-3.264L24.089,21' transform='translate(-8.439 -10.549)' fill='none' stroke='%234a4948' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  @include commonProperties();
  width: #{$size}px;
  height: #{$size}px;
}

@mixin chips($bgColor, $borderColor) {
  background: $bgColor;
  padding: toRem(9) toRem(20);
  color: $borderColor;
  border-radius: 24px;
  border: $borderColor;
  cursor: pointer;
  @include font-size(9);
}

@mixin clearButton($width) {
  display: flex;
  width: #{$width}px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: none;
  border: 0px;
  padding: 0;
}

@mixin commonProperties() {
  content: '';
  border: 0;
  display: inline-block;
  background-repeat: no-repeat;
  transition: 0.1s ease-in-out;
  cursor: pointer;
  background-color: transparent;
}

@mixin rightChevron($color: $secondaryRed) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.358' height='18.939' viewBox='0 0 10.358 18.939'%3E%3Cg id='Group_13955' data-name='Group 13955' transform='translate(-225.082 -294.939)'%3E%3Cline id='Line_9' data-name='Line 9' y1='8.409' x2='8.237' transform='translate(226.143 304.409)' fill='none' stroke='%23ce5163' stroke-linecap='square' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cline id='Line_10' data-name='Line 10' x2='8.237' y2='8.409' transform='translate(226.143 296)' fill='none' stroke='%23ce5163' stroke-linecap='square' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  color: $color;
  width: 10px;
  height: 20px;
}

@mixin downCheveron($color: $secondaryRed) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.061' height='9.591' viewBox='0 0 17.061 9.591'%3E%3Cpath id='Path_11870' data-name='Path 11870' d='M0,0,8,8l8-8' transform='translate(16.53 9.061) rotate(180)' fill='none' stroke='%234a4948' stroke-width='1.5'/%3E%3C/svg%3E%0A");
  @include commonProperties();
  color: $color;
  width: 20px;
  height: 20px;
}

@mixin prevCheveron() {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10.588' height='19.263' viewBox='0 0 10.588 19.263'%3E%3Cpath id='Path_11669' data-name='Path 11669' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(6181.055 1039.819) rotate(90)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3C/svg%3E%0A");
  @include commonProperties();
  width: 20px;
  height: 20px;
}

@mixin fwdCheveron() {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.588' height='19.263' viewBox='0 0 17.588 19.263'%3E%3Cg id='Group_13048' data-name='Group 13048' transform='translate(-703.467 -1491.816)'%3E%3Cpath id='Path_11667' data-name='Path 11667' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-5460 471.26) rotate(-90)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3Cpath id='Path_11668' data-name='Path 11668' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-5467 471.26) rotate(-90)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  width: 20px;
  height: 20px;
}

@mixin rightArrow($width: 25, $height: 20) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='#{$width}' height='#{$height}' viewBox='0 0 25.811 16.121'%3E%3Cg id='Group_13960' data-name='Group 13960' transform='translate(-4.25 -6.939)'%3E%3Cline id='Line_8' data-name='Line 8' x2='24' transform='translate(5 15)' fill='none' stroke='%23ce5163' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cline id='Line_9' data-name='Line 9' y1='7' x2='6.857' transform='translate(22.143 15)' fill='none' stroke='%23ce5163' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cline id='Line_10' data-name='Line 10' x2='6.857' y2='7' transform='translate(22.143 8)' fill='none' stroke='%23ce5163' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  color: $secondaryRed;
  width: #{$width}px;
  height: #{$height}px;
}

@mixin leftLongArrow($width: 61, $height: 20) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='61.055' height='19.263' viewBox='0 0 61.055 19.263'%3E%3Cg id='Group_12980' data-name='Group 12980' transform='translate(5285 -11181.264) rotate(-90)'%3E%3Cline id='Line_880' data-name='Line 880' x2='60' transform='translate(-11191 -5285) rotate(90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-10160.708 -11405)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  color: $white;
  width: #{$width}px;
  height: #{$height}px;
}

@mixin rightLongArrow($width: 61, $height: 20) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='61.055' height='19.263' viewBox='0 0 61.055 19.263'%3E%3Cg id='Group_12980' data-name='Group 12980' transform='translate(5285 -11181.264) rotate(-90)'%3E%3Cline id='Line_880' data-name='Line 880' x2='60' transform='translate(-11191 -5285) rotate(90)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-10160.708 -11405)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  content: '';
  @include commonProperties();
  width: #{$width}px;
  height: #{$height}px;
  min-width: #{$width}px;
}

@mixin rightLongWhiteArrow($width: 61, $height: 20) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='61.055' height='19.263' viewBox='0 0 61.055 19.263'%3E%3Cg id='Group_12980' data-name='Group 12980' transform='translate(5285 -11181.264) rotate(-90)'%3E%3Cline id='Line_880' data-name='Line 880' x2='60' transform='translate(-11191 -5285) rotate(90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-10160.708 -11405)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  width: #{$width}px;
  height: #{$height}px;
}

@mixin downLongArrow($width: 20, $height: 61) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.263' height='61.055' viewBox='0 0 19.263 61.055'%3E%3Cg id='Group_12392' data-name='Group 12392' transform='translate(11200.527 5285)'%3E%3Cline id='Line_880' data-name='Line 880' x2='60' transform='translate(-11191 -5285) rotate(90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-10160.708 -11405)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  color: $secondaryRed;
  width: #{$width}px;
  height: #{$height}px;
}

@mixin downLongRedArrow($width: 20, $height: 61) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.263' height='61.055' viewBox='0 0 19.263 61.055'%3E%3Cg id='Group_12392' data-name='Group 12392' transform='translate(11200.527 5285)'%3E%3Cline id='Line_880' data-name='Line 880' x2='60' transform='translate(-11191 -5285) rotate(90)' fill='none' stroke='%23df4661' stroke-width='1.5'/%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-10160.708 -11405)' fill='none' stroke='%23df4661' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  color: $secondaryRed;
  width: #{$width}px;
  height: #{$height}px;
}

@mixin downWhiteArrow($width: 20, $height: 61) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.263' height='61.055' viewBox='0 0 19.263 61.055'%3E%3Cg id='Group_12392' data-name='Group 12392' transform='translate(11200.527 5285)'%3E%3Cline id='Line_880' data-name='Line 880' x2='60' transform='translate(-11191 -5285) rotate(90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-10160.708 -11405)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  color: $secondaryRed;
  width: #{$width}px;
  height: #{$height}px;
}

@mixin downShortArrow($width: 20, $height: 20) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.263' height='20.659' viewBox='0 0 19.263 20.659'%3E%3Cg id='Group_14697' data-name='Group 14697' transform='translate(11200.527 5244.604)'%3E%3Cline id='Line_880' data-name='Line 880' y2='19.604' transform='translate(-11191 -5244.604)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-10160.708 -11405)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  color: $secondaryRed;
  width: #{$width}px;
  height: #{$height}px;
}

@mixin doublePrevArrow($width: 20, $height: 20) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.588' height='19.263' viewBox='0 0 17.588 19.263'%3E%3Cg id='Group_13049' data-name='Group 13049' transform='translate(721.055 1511.079) rotate(180)'%3E%3Cpath id='Path_11667' data-name='Path 11667' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-5460 471.26) rotate(-90)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3Cpath id='Path_11668' data-name='Path 11668' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-5467 471.26) rotate(-90)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  color: $white;
  width: #{$width}px;
  height: #{$height}px;
  cursor: pointer;
}

@mixin prevArrow($width: 20, $height: 20) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.055' height='19.263' viewBox='0 0 21.055 19.263'%3E%3Cg id='Group_14227' data-name='Group 14227' transform='translate(-5223.945 11200.527) rotate(90)'%3E%3Cline id='Line_880' data-name='Line 880' x2='20' transform='translate(-11191 -5245) rotate(90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-10160.708 -11405)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  color: $white;
  width: #{$width}px;
  height: #{$height}px;
  cursor: pointer;
}

@mixin nextArrow($width: 20, $height: 20) {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.055' height='19.263' viewBox='0 0 21.055 19.263'%3E%3Cg id='Group_14228' data-name='Group 14228' transform='translate(5245 -11181.264) rotate(-90)'%3E%3Cline id='Line_880' data-name='Line 880' x2='20' transform='translate(-11191 -5245) rotate(90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-10160.708 -11405)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
  @include commonProperties();
  color: $white;
  width: #{$width}px;
  height: #{$height}px;
  cursor: pointer;
}
