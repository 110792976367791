.subsector-carousel {
  padding: 3.1rem 3rem;

  &__header {
    display: flex;
    flex-direction: row;

    &__left {
      flex: 0 0 20%;
      justify-content: flex-start;
      align-items: flex-start;

      h3 {
        @include font-size(24.38);
        font-weight: normal;
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      flex: 0 0 80%;

      p {
        max-width: 40%;
      }

      ul {
        max-width: 40%;
      }
    }

    &__viewall {
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 1rem;
    }
  }

  &__cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    &--btn {
      @include cta-round-button($themeRed, $white, 40);
    }
  }
}
