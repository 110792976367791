.collection {
  &__detail {
    &--header {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }

    &--title {
      @include font-size(19.3);
      font-weight: 300;
      padding: 1rem 0;
    }

    &--desc {
      @include font-size(10);
      font-weight: 200;
    }
  }

  .article__grid {
    .articlecard__label {
      display: flex !important;
    }
  }

  .articlecard--imgwrap {
    max-width: 100%;
  }

  .articlecard__label {
    display: none;
  }
}
