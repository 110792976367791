.persondetail {
  display: grid;
  align-items: flex-start;
  position: relative;
  grid-template-areas:
    'profile image'
    'about image'
    'office image'
    'footer image';
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 7.25rem;
  padding: toRem(35) toRem(50);

  &__wrapper {
    padding: 0 0 toRem(80) 0;
    flex-direction: row-reverse;
    align-items: flex-start;
    width: 100%;
  }
  &__profile {
    flex: 0 0 55%;
    &--name {
      @include font-size(30.63);
      font-weight: normal;
    }
    &__card {
      padding-top: toRem(80);
      &--row {
        align-items: center;
        display: grid;
        grid: auto / 1fr 3fr;
        column-gap: 1rem;
      }
      &--key {
        flex: 0 0 30%;
      }
      &--value {
        align-items: center;
        display: flex;
        gap: 1rem;
        width: 100%;
        justify-content: flex-start;
        display: flex;
      }
      &--link {
        align-items: center;
      }

      &__link {
        &:first-of-type {
          padding-top: toRem(10);
        }
      }
    }
  }
  &--bsblogo {
    right: 0px;
    bottom: 0px;
  }
  &__about {
    justify-content: flex-start;
    &--title {
      padding-bottom: 2.5rem;
    }
  }
  &__office {
    padding: 3.75rem 3.12rem;
    .offlocard {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
    }
  }
  &__footer {
    padding: 1.7rem 3rem;
    flex-wrap: nowrap;
    justify-content: space-between;
    &__contact {
      gap: 2rem;
      flex-wrap: nowrap;
    }
    &__tag {
      gap: 1.5rem;
    }

    &__profile {
      flex-wrap: nowrap;
      flex: unset;
    }
  }
}
.persondetail {
  align-items: flex-start;
  position: relative;
  &__wrapper {
    flex-direction: row-reverse;
    align-items: flex-start;
    width: 100%;
  }
  &__profile {
    flex: 0 0 55%;
    &--name {
      @include font-size(30.63);
      font-weight: normal;
      margin-bottom: toRem(10);
    }
    &__card {
      &--row {
        align-items: center;
      }
      &--key {
        flex: 0 0 30%;
      }
      &--value {
        align-items: center;
        display: flex;
        gap: 1rem;
        flex: 0 0 60%;
      }
      &--link {
        align-items: center;
      }
    }
  }

  &--bsblogo {
    right: 0px;
    bottom: 0px;
  }

  &__about {
    justify-content: flex-start;
    &--title {
      padding-bottom: 2.5rem;
    }
  }

  &__office {
    padding: toRem(80) 0 toRem(80) 0;
    .offlocard {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
    }
  }
  &__footer {
    padding: 1.7rem 3rem;
    flex-wrap: nowrap;
    justify-content: space-between;
    &__contact {
      gap: 2rem;
      flex-wrap: nowrap;
    }
    &__tag {
      gap: 1.5rem;
    }

    &--save {
      display: flex;
    }

    &--call,
    &--email {
      border: 0;
      border-radius: 0;
      height: auto;
      width: auto;
    }

    &-contact-text {
      display: flex;
    }
  }

  &__imgwrapper {
    position: sticky;
  }
}
