.heroarticle {
    padding: 1rem 2rem;
    @include bgimageconfig();
    @include background-shade();

    &:hover {
        cursor: pointer;
        background-size: 150% !important;
        -webkit-transition: all 0.3s ease-in-out;
    }

    a{
        color: $white;
        text-decoration: none;
    }
    
    .accent-color{
        color: $secondaryRed;
    }

    &__container{
        display: flex;
        width: 100%;
    }
   
    &__about {
        flex: 0 0 90%;
        max-width: 90%;
        color: $white;
        z-index: 2;
        padding-bottom: 3rem;
        &--title {
            @include font-size(30.625);
            font-weight: 600;
        }
        &--subtext {
            @include font-size(12.5);
        }
    }

    &__cta {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;

        &--btn {
            @include cta-round-button($themeRed, $white, 32);
        }
    }

    &__txtarrow {
        display: none;

        @include breakpoint($device-large) {
            display: flex;
          }

        justify-content: end;
        align-items: center;
        flex-direction: column;
        gap: 2.4rem;
        z-index: 2;

        &--label {
            color: $white;
            margin: 0;
            transform: rotate(270deg);
          
        }

        &--arrow{
            @include downLongArrow();
        }

        &--arrow--right{
            @include rightLongArrow()
        }
       
    }

    &__article {
        flex: 0 0 100%;

        @include breakpoint($device-large) {
            flex: 0 0 90%;
            max-width: 90%;
          }

          
        padding: 8rem 1rem 3rem 0;
        color: $white;
        z-index: 2;

        &__header{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: .8rem;
        }
        &--title {
            @include font-size(16);
            width: 100%;
            margin: 0.5rem 0;
        }
        &--category {
            @include font-size(7);  
        }
        &--divider{
            width: 1px;
            height: .8rem;
            background-color: $white;
        }
        &--xtime{
            @include font-size(7);
        }

    }

   
}