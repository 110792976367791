.searchres{
    padding: 2.5rem 3rem;
    flex-direction: row;

    &__header{
        flex-direction: row;
    }
    &__sidenav{
        display: flex;
        flex: 0 0 15%;  
        gap: 1.56rem;
    }

    &__container{
        display: flex;
        flex: 0 0 75%;
        flex-direction: column;
    }

    &__header{
        justify-content: space-between;       
    }

    
}