.sector{
    display: flex;
    flex-direction: column;

    p{
        margin: 0;
    }

    &__keycontacts{
        display: flex;
        flex-direction: column;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 4rem 1rem;
        background: $themeGray;
        color: $white;
        gap: 1.875rem;

        &__header{
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            gap: 1.875rem;
        }

        &__viewall{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1.25rem;
            text-decoration: none;
            color: $white;
            @include font-size(10);
            font-weight: 300;
 
            button{
                @include cta-round-button($accentRed, $white, 40) ;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
              
            }
        }
        &--title{
            @include font-size(19.37);
            font-weight: 300;
            color: $white;
        }
    }

   
    
}