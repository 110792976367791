// ================================== //
//    #CORE - BREAKPOINTS
// ================================== //
$mobile: 240px;
$large-mobile: 400px; // xs
$tablet: 600px; // sm
$large-tablet: 800px; // md
$small-desktop: 1030px; // desktop
$desktop: 1240px; // lg
$large-desktop: 1600px; // xl

$ipad: 768px; // Ipad
$device-large: 1024px; // For Viewport 1024px
$device-xlarge: 1440px; // For Viewport 1440px
$device-xxlarge: 1920px; // For Viewport 1920px

@include add-breakpoint('mobile', $mobile);
@include add-breakpoint('large-mobile', $large-mobile);
@include add-breakpoint('tablet', $tablet);
@include add-breakpoint('large-tablet', $large-tablet);
@include add-breakpoint('small-desktop', $small-desktop);
@include add-breakpoint('desktop', $desktop);
@include add-breakpoint('large-desktop', $large-desktop);
// $breakpoint-has-widths: ('mobile', 'tablet', 'large-tablet', 'desktop', 'large-desktop');

@include add-breakpoint('ipad', $ipad);
@include add-breakpoint('device-large', $device-large);
@include add-breakpoint('device-xlarge', $device-xlarge);
@include add-breakpoint('device-xxlarge', $device-xxlarge);


.grid {
  &--two-col {
    @include breakpoint($desktop) {
      display: grid;
      grid-template-areas: 'aside-top main' 'aside-bottom main';
      grid-template-columns: minmax(200px, 350px) minmax(400px, 1000px);
      grid-template-columns: 1fr 3fr;
      grid-template-rows: auto 1fr;

      .main-content {
        grid-area: main;
        overflow: hidden;
        padding-left: $spacing-unit--xl;
        padding-right: $spacing-unit--xl;
        padding-top: $spacing-unit--l;
      }

      .sidebar-top {
        grid-area: aside-top;
      }

      .sidebar-bottom {
        grid-area: aside-bottom;
      }
    }

    main {
      padding: $spacing-unit;

      @include breakpoint($tablet) {
        padding: $spacing-unit--l;
      }
    }

    .sidebar {
      background-color: $lighterGray;
      padding-bottom: $spacing-unit;

      @include breakpoint($desktop) {
        padding-bottom: 0;
      }
    }
  }

  &--check-out {
    grid-template-areas: 'aside-top main' 'aside-bottom main';
    grid-template-columns: 3fr 1.25fr;

    .main-content {
      background-color: $lightestGray;
      grid-area: aside-bottom;
    }

    .sidebar-bottom {
      background-color: $gridGray;
      grid-area: main;
    }
  }

  &--customer-details {
    display: flex;
    flex-direction: column;

    @include breakpoint($desktop) {
      display: grid;
    }

    .main-content {
      order: 2;
      position: relative;
      z-index: 1;

      @include breakpoint($desktop) {
        align-items: flex-start;
        display: flex;
        flex-wrap: wrap;
      }
    }

    .sidebar-bottom {
      order: 1;
    }
  }

  &--register-account {
    grid-template-areas: 'aside-top main' 'aside-bottom main';
    grid-template-columns: 1fr 3fr;

    &.wrapper {
      display: flex;
      flex-direction: column;
      padding: 0;

      @include breakpoint($desktop) {
        display: grid;
      }
    }

    .main-content {
      grid-area: main;
      justify-content: center;
    }

    .sidebar-bottom {
      background-color: $white;
      grid-area: aside-bottom;
      height: 100%;
      opacity: 1;
      transition: .4s all ease-in-out;
      visibility: visible;
      width: 100%;

      @include breakpoint($desktop) {
        padding: $spacing-unit--xl $spacing-unit;
      }
    }

    &.hide-sidebar {
      display: flex;

      .sidebar-bottom {
        opacity: 0;
        position: absolute;
        visibility: hidden;
      }
    }
  }

  &--thank-you {
    display: flex;
    flex-direction: column;

    @include breakpoint($desktop) {
      display: grid;
    }

    .sidebar-bottom {
      order: 1;

      .check-out--progress-bar {
        background: $white;
        padding: $spacing-unit--l;

        @include breakpoint($desktop) {
          display: none;
        }
      }
    }

    .main-content {
      order: 2;

      .check-out--progress-bar {
        display: none;
      }

      @include breakpoint($desktop) {
        align-items: flex-start;
        display: flex;

        .check-out--progress-bar {
          display: flex;
        }
      }
    }
  }

  &--find-my-solution-results {
    grid-template-columns: 1fr 1fr;
  }
}
