.guideres__card {

    flex: 0 0 21%;
    max-width: 21%;

    @include breakpoint($device-xlarge) {
        flex: 0 0 23%;
        max-width: 23%;
    }

    &--detail{
        max-width: 100%;
        margin: 4.125rem 0 3.125rem 0;

        @include breakpoint($device-large) {
            max-width: 80%;
          }
    }

}