.glide {
  // width: calc(100%);
  .carousel__indicadors .glider-dot{
    display: block;
    width: 30px;
    height: 4px;
    background: #fff;
    opacity: .2;
    border-radius: 0;
  }
  .carousel__indicadors .glider-dot:HOVER{
    opacity: .5;
  }
  .carousel__indicadors .glider-dot.active{
    opacity: 1;
  }

  

  &__track {
    overflow: hidden;
    transition: .5s transform ease-in-out;
    will-change: transform;
  }

  &__arrow {
    overflow: hidden;
    position: absolute;
    top: 50%;
    width: 38px;
    height: 38px;
    background: $themeRed;
    color: $white;
    border-radius: 50%;

    @include breakpoint($device-large) {
      width: 38px;
      height: 38px;
    }

    @include breakpoint($device-xlarge) {
      width: 72px;
      height: 72px;
    }

    svg {
      fill: $white;
      stroke: none;
      height: 8px;
      width: 13px;
    }
  }

  &__arrows{
    display: flex;
    position: absolute;
    top: 40%; 
    width: 99%;   
  }

  &__arrow--left {
    left: 2em;
  }

  &__arrow--right {
    right: 0em;
   
  }


  &__bullets {
    @include breakpoint($device-large) {
      padding: 3.2rem 0;
    }

    &__bullet {
      border: 1px solid $themeRed;
      border-radius: 50%;
    }
  }

  &__slides {
    white-space: normal;
    list-style: none;
    // width: auto !important;
    // transform: translate3d(0px, 0px, 0px) !important;
    // width: calc(100%) !important;

    @include breakpoint($desktop) {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      // width: 100% !important;
    }
  }

  &__anchor {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }


  &__bullets {
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    padding: 2rem 0;
    position: relative;
    transform: none;
    width: 100%;
    z-index: 0;
  }

  &__bullet {
    background: transparent;
    border: 1px solid $themeRed;
    box-shadow: none;
    height: 12px;
    margin: 0 1rem;
    overflow: hidden;
    text-align: left;
    text-indent: -9999px;
    white-space: nowrap;
    width: 12px;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    &:focus,
    &:hover {
      background: $red;
      border: 2px solid $red;
    }
  }

  &__bullet--active {
    background: $themeRed;
  }

  &__arrows {
    &.hide {
      display: none;
    }

    cursor: pointer;

    button {
      border: 0;
      box-shadow: none;
      color: #484848;
      height: 50px;
      overflow: hidden;
      width: 50px;

      &[disabled=disabled] {
        opacity: .5;
      }

      svg {
        height: 28px;
        width: 28px;
      }
    }
  }

  &__arrow {
    padding: 0;
  }
}