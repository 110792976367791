.register{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    &__left {
        flex: 0 0 32%;
        max-width: 32%;
        padding: 0 3rem;
        justify-content: center;
    
        &__cta{
            flex-direction: row;
            align-items: center;
        }
    }

    &__right{
        flex: 0 0 60%;
        max-width: 60%;
    }

    .glide__slides{
        padding: 0;
    }

    &-card{
        &__content{
            padding: 31rem 3.125rem 3.8rem 3.125rem;

        }
    }
    .glide{
        &__arrows{
            position: absolute;
            top: 30%;
            left: auto;
            right: 8rem;
        }
    }
}
