.rich-text {
  padding: toRem(64) toRem(20);
  margin: auto;

  @media only screen and (min-width: $tablet) {
    padding: toRem(64) toRem(50);
  }

  @media only screen and (min-width: $device-large) {
    padding: toRem(64) toRem(290);
  }

  h2 {
    @extend .h3;
    font-weight: 300;
  }

  h3 {
    @extend .body-large;
    text-transform: uppercase;
    font-family: 'AvenirRegular';
    font-style: normal;
    font-weight: 500;
  }

  h4 {
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  table {
    text-align: left;
    display: block;
    border-collapse: collapse;
    overflow-x: auto;
    margin: toRem(24) 0;

    thead {
      th,
      th > p {
        background-color: $themeGray;
        color: $white;
        font-weight: 800;
        border: 1px solid $themeGray;
      }
    }

    tr {
      &:first-of-type {
        td {
          background-color: $themeGray;
          color: $white;
          font-weight: 800;
          border: 1px solid $themeGray;

          p {
            font-weight: 800;
          }
        }
      }

      &:nth-child(even) {
        background-color: rgba($themeGray, 0.15);
      }
    }

    thead + tbody {
      tr {
        &:first-of-type {
          td {
            background-color: $white;
            color: $themeGray;
            font-weight: 400;
            border-right: 1px solid rgba($themeGray, 0.15);
            border-top: 0;
            border-bottom: 0;

            &:first-child {
              border-left: 1px solid rgba($themeGray, 0.15);
            }
          }
        }
      }
    }

    th,
    td {
      min-width: toRem(276);
      padding: toRem(10);
      border-right: 1px solid rgba($themeGray, 0.15);
      border-top: 0;
      border-bottom: 0;
      vertical-align: top;

      @media only screen and (min-width: $tablet) {
        min-width: auto;
      }
    }

    th {
      &:last-child {
        border-right: 1px solid $themeGray;
      }
    }

    tr {
      &:last-child {
        border-bottom: 1px solid rgba($themeGray, 0.15);
      }
    }

    td:first-child {
      border-left: 1px solid rgba($themeGray, 0.15);
    }

    p {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    & + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      padding-top: toRem(8);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: normal;
    margin-bottom: toRem(24);
  }

  p {
    font-size: toRem(16);
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: toRem(24);
    margin-top: 0;

    & + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + h6,
    + table {
      padding-top: toRem(8);
    }
  }

  ul {
    padding-left: toRem(20);

    li {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  a {
    text-decoration-line: underline;
  }
}
