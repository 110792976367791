.sector-listing {
  padding: 3.4rem 3rem;


  &__header {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    gap: 1rem;

    &--left{
      gap: 1rem;
      padding: 2rem 0;
    }
  }
 
  &__container{
    flex-direction: row;
    text-align: center;
  }


  &__card {
    overflow: hidden;
    flex: 0 0 35%;
    height: auto;
  }

  &__cardopen {
    flex: 0 0 65%;
    padding: 0 4.25rem;
    height: max-content;

    &__header {
      padding: 0;
    }

    &__content {
      padding: 3rem 0;
    }

    &__services {
      p {
        padding: 0;
      }

      ul {
        justify-content: space-between;

        li {
          flex: 0 0 38%;
        }
      }
    }

    &--title {
      @include font-size(15.63);
      font-weight: normal;
    }
    
  }

}