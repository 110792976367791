.offlocard {
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &--image {
    flex: 0 0 30%;
  }

  &__content {
    flex: 0 0 70%;
    justify-content: space-between;

      &--city{
        flex: 0 0 40%;
        @include font-size(15.6);
      }
     

      &__right{
        flex: 0 0 60%;
    }

  
  }

}