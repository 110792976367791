// ================================== //
//    #UTILITY - MARGIN CONTROL
//
// Note: this keeps the margins consistent on the text elements between mobile and desktop
// Adding a breakpoint will create unified control across breakpoints
// ================================== //

$spacing-unit: 1.5rem;
$spacing-unit--xxs: $spacing-unit / 8;
$spacing-unit--xs: $spacing-unit / 4;
$spacing-unit--sm: $spacing-unit / 3;
$spacing-unit--half: $spacing-unit / 2;
$spacing-unit--ss: $spacing-unit / 1.5; // ~ 10px
$spacing-unit--md: $spacing-unit * 1.33; // ~ 20px
$spacing-unit--mdl: $spacing-unit * 1.6; // ~ 24px
$spacing-unit--l: $spacing-unit * 2;
$spacing-unit--lml: $spacing-unit * 2.66; // ~ 40px
$spacing-unit--ll: $spacing-unit * 3;
$spacing-unit--xl: $spacing-unit * 4;
$spacing-unit--xll: $spacing-unit * 5; // 75px
$spacing-unit--xxl: $spacing-unit * 8;
$spacing-unit--xxxl: $spacing-unit * 10;
$mobileMargin: $spacing-unit;

@mixin margin-control($center: false, $override: false) {
  @if $center == true {
    margin: $spacing-unit auto;
  } @else if $override == true {
    margin: 0 !important;
  } @else {
    margin: $spacing-unit 0;
  }
}
