.sector-carousel-boilerplate {
  padding: 0;
  text-align: center;
  * {
    -webkit-tap-highlight-color: transparent;
  }

  &__card {
    overflow: hidden;
    height: 420px;
  }

  &__cardopen {
    height: 420px;
    width: 100%;
    &__services {
      p {
        padding: 0;
      }
      ul {
        li {
          flex: 0 0 50%;
        }
      }
    }
  }
}
