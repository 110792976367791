.wware {
  display: flex;
  flex-direction: column;

  .subsector-carousel {
    padding: 0;
  }

  &__offices {
    display: flex;
    flex-direction: column;
    padding: 3.75rem 1rem;

    &__header {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      &__left {
        display: flex;
        flex-direction: column;
      }

      &__right {
        display: none;
        flex-direction: column;
      }

      &--title {
        margin: 0;
      }

      &--para {
        @include font-size(12.5);
        font-weight: 200;
      }

      &--regioncount {
        @include font-size(12.5);
        font-weight: 200;
      }
    }
  }
}
