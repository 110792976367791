// ================================== //
//    #UTILITY - COLORS
// ================================== //

@function palette($palette, $tone: 'base') {
  @return map-get(map-get(map-get($palettes, colors), $palette), $tone);
}

$palettes: (
  colors: (
    black: (
      base: #000,
    ),
    white: (
      base: #fff,
      off: #EDEDED,
    ),
    gray: (
      gray100: #e3e3e3,
      gray200: #efefef,
      gray300: #dcdddf,
      gray400: #b4bdc1,
      gray500: #eaebeb,
      gray600: #d4d4d4,
      gray800: #5c6f76,
      gray900: #A5A4A4,
      lighter: #f5f7f2,
      lightest: #f7f7f7,
      grid: #ededee,
      light: #eaebe5,
      base: #ececee,
      medium: #c4c4c4,
      dark: #7e7e7e,
      darker: #414042,
      darkest: #2f2f30,
      placeholder:#e1e1e1,
      theme: #4A4948,
      boxshadow:#4A49484D,
      border: #B3B3B3,
      ltBorder:#EBEBEB,
      secondary: #3B3B3B,
      para:#5C5B5A,
      chip:#F2F2F2,
      cardBorder:#C9C8C8,
      background:#F4F5F6
    ),
    green: (
      green100: #29a443,
      pastelLight: #e3f7ea,
      pastel: #cfe9d9,
      base: #1faa32,
      light: #5bbe6e, 
      lighter: #8fd08a, 
      lightest: #f1f8f4,
      medium: #96d28f, 
      brand: #20aa4d, 
      dark: #36944b, 
      darker: #317258,
      border: #165C7D
    ),
    red: (
      base: #912c25,
      bright: #db1a00,
      theme: #CE5063,
      secondary: #DF4661,
      accent: #CE5163,
      darkPink: #DF4661
    ),
    orange: (
      base: #E7893C
    ),
    blue: (
      blue100: #273c43,
      blue200: #1e424e,
      base: #0f2f45,
      brand: #002f47,
      dark: #0f2f45,
      gray: #5d6f76,
      navy: #1d323a,
      purple: #1c2a49,
      darker: #17333d,
      nav: #17333c,
      lightest: #f0f0ef
    )
  )
);

$white: palette(white);
$offWhite: palette(white, off);
$black: palette(black);

$gray: palette(gray);
$gray100: palette(gray, gray100);
$gray200: palette(gray, gray200);
$gray300: palette(gray, gray300);
$gray400: palette(gray, gray400);
$gray500: palette(gray, gray500);
$gray600: palette(gray, gray600);
$gray800: palette(gray, gray800);
$gray900: palette(gray, gray900);
$darkGray: palette(gray, dark);
$darkerGray: palette(gray, darker);
$darkestGray: palette(gray, darkest);
$lightGray: palette(gray, light);
$cardBorderGray: palette(gray, cardBorder);
$lighterGray: palette(gray, lighter);
$lightestGray: palette(gray, lightest);
$gridGray: palette(gray, grid);
$mediumGray: palette(gray, medium);
$themeGray: palette(gray, theme);
$boxShadow: palette(gray, boxshadow);
$secondaryGray:palette(gray, secondary);
$paraGray:palette(gray, para);
$placeholderGray: palette(gray, placeholder);
$borderGray: palette(gray, border);
$ltBorderGray: palette(gray, ltBorder);
$chipGray: palette(gray, chip);
$backgroundGray: palette(gray, background);



$brandGreen: palette(green, brand);
$green100: palette(green, green);
$green: palette(green);
$darkGreen: palette(green, dark);
$darkerGreen: palette(green, darker);
$lightGreen: palette(green, light);
$lighterGreen: palette(green, lighter);
$lightestGreen: palette(green, lightest);
$mediumGreen: palette(green, medium);
$brandGreen: palette(green, brand);
$pastelGreen: palette(green, pastel);
$pastelGreenLight: palette(green, pastelLight);
$borderGreen: palette(green, border);

$linkDark: palette(green, dark);
$linkLight: $lightGreen;

$red: palette(red);
$brightred: palette(red, bright);
$themeRed: palette(red, theme);
$secondaryRed: palette(red, secondary);
$accentRed: palette(red, accent);
$darkPink: palette(red,darkPink );
$orange: palette(orange);

$blue: palette(blue);
$blue100: palette(blue, blue100);
$blue200: palette(blue, blue200);
$darkBlue: palette(blue, dark);
$darkerBlue: palette(blue, darker);
$brandBlue: palette(blue, brand);
$navBlue: palette(blue, nav);
$navyBlue: palette(blue, navy);
$grayBlue: palette(blue, gray);
$purpBlue: palette(blue, purple);
$lightestBlue: palette(gray, lightest);


// Returns flattened $palettes color map
@function get-flat-palette($map) {
  $colors: ();

  @each $color, $value in map-get($map, colors) {
    @each $name, $tone in $value {
      // key for "base" color is the color name itself, e.g. "gray"
      // key for other tones combines color and tone name, e.g. "gray-light"
      $key: if($name == 'base', $color, $color + '-' + $name);
      $colors: map-merge(
        $colors,
        (
          $key: $tone
        )
      );
    }
  }

  @return $colors;
}

// Save palette as flat map, used by styleguide.scss and variables.scss
$color-map: get-flat-palette($palettes);
