.prefmng {
    display: flex;
    flex-direction: column;
    padding: 2.5rem 1rem;

    &__header {
        @include font-size(24.38);
        font-weight: normal;
    }

    &__container {
        display: flex;
        flex-direction: column;
    }

    &__sidenav {
        display: flex;
        flex-direction: column;

        &__list {
            padding: 0;

            li {
                list-style: none;
            }
        }

        &__menu {
            display: none;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        padding: 2.5rem 0;
        border-top: 1px solid $offWhite;

        &__area,
        &__location,
        &__comm {
            display: flex;
            flex-direction: column;
        }

        &--error, &--success{
          justify-content: center;
          align-items: center;  
          display: none;

          @include font-size(12);
        }
   
        &--error{
            color: $themeRed;
        }

        &--success{
            color: $darkPink;
        }

        &--options {
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 1rem;



        }

        &--option {
            list-style: none;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            padding: .7rem .5rem;

            label {
                @include font-size(11);
            }

            input[type="checkbox"] {
                accent-color: $themeGray;
                box-shadow: none;
                width: 25px;
                margin: 0;
                cursor: pointer;
                border: 2px solid $themeGray;

                &:checked~label {
                    &:before {
                        border: 2px solid $themeGray;
                    }

                    &:after {
                        transform: rotate(-45deg) scale(1);
                    }
                }

                &:focus+label::before {
                    outline: 0;
                }
            }
        }


        &__unsubscribe {
            display: flex;
            padding: 1rem 0;
            cursor: pointer;
            &:before {
                position: absolute;
                content: '\2715';
                height: 20px;
                width: 20px;
            
            }

            &--label{
                margin: 0;
                padding: 0 2rem;
            }
        }


        &__submit {
            display: flex;
            padding: 2rem 0;

            &--ctalink {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1.25rem;
                text-decoration: none;
                color: $themeGray;
                @include font-size(10);
                font-weight: 300;

                &::before {
                    content: '';
                    @include cta-round-button($accentRed, $white, 40);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                }
            }
        }

    }

}