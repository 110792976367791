@mixin bgimageconfig() {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
}


@mixin scrollbar($bgColor, $trackColor) {
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 2em;
    cursor: pointer;

    &::-webkit-scrollbar {
        width: 6px;
        height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: $bgColor;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        background: $trackColor;
    }
}

