.regions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2.5rem 1rem;

  .gray-color {
    color: $gray900;
  }
  &__header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    &__left {
      display: flex;
      flex-direction: column;
    }

    &__right {
      display: flex;
      flex-direction: column;
    }

    &--title {
      @include font-size(24.5);
      font-weight: 300;
      margin: 0;
    }

    &--para {
      @include font-size(12.5);
      font-weight: 200;
    }

    &--regioncount {
      @include font-size(12.5);
      font-weight: 200;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 1.88rem;
    padding: 1.88rem 0;

    .sector-carousel-boilerplate {
      width: 100%;
      &__card {
        @include background-shade();

        &__content {
          padding: toRem(30) toRem(30) toRem(40);
        }

        &__cta {
          margin: toRem(-10);
        }

        &--desc {
          color: $white;
          @include font-size(12.5);
          margin: 0;
          text-align: left;
        }

        &--title {
          padding: 0;
        }

        &--btn {
          line-height: 1;
        }
      }

      &__cardopen {
        padding: toRem(30);
        row-gap: toRem(30);

        &__header {
          padding: 0;
        }

        &--desc {
          &:last-of-type {
            margin-bottom: 0;
          }
        }
        &--title {
          margin: toRem(50) 0 0 0;
        }
        &__services {
          margin: 0;
          padding: toRem(30) toRem(20);

          ul {
            margin: 0;
            row-gap: toRem(25);
            li {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
