.video{
    padding: 3rem ;

}



.podcast{
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem ;
}