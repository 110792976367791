.people {
  display: flex;
  flex-direction: column;
  padding: 0;

  &__gridcont {
    padding: 5rem 1rem;
    color: $themeGray;

    .people__header {
      color: $themeGray;
    }

    .people__header__cta--ahref {
      color: $themeGray;
      width: max-content;
    }

    .paginator {
      justify-content: center;
      display: none;
    }
  }

  &__filsort {
    display: flex;
    gap: 0.9rem;
    padding: 0.5rem 0;

    &--ctafilter,
    &--ctasort {
      display: flex;
      flex: 1;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      border-radius: 28px;
      @include font-size(10);
    }

    &--ctafilter {
      background: $chipGray 0% 0% no-repeat padding-box;
      color: $themeGray;
      border: 1px solid $chipGray;
      cursor: pointer;
    }

    &--ctasort {
      background: $white 0% 0% no-repeat padding-box;
      color: $themeGray;
      border: 1px solid $themeGray;
      cursor: pointer;
    }
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1.25rem 0;
    margin: 0;

    &__li {
      display: flex;
      flex: 0 0 100%;
    }
  }

  p {
    margin: 0;
  }

  &__container {
    padding: 1rem;
    background: $themeGray;
    color: $white;
    padding: 3.75rem 1rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: $white;
    align-items: flex-start;
    text-align: center;
    justify-content: space-between;
    gap: 1.88rem;
    padding-bottom: 1.6rem;

    & + .people-carousel__carousel {
      padding-top: 0 !important;
    }

    &--title {
      @include font-size(19.38);
      font-weight: 300;
      margin-bottom: 0;
    }

    &--count {
      @include font-size(12.5);
    }

    &__cta {
      display: flex;
      padding-right: 7rem;

      &--ahref {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.2rem;
        color: $white;
        text-decoration: none;
        @include font-size(10);
        width: max-content;

        &::before {
          content: '';
          @include cta-round-button($accentRed, $white, 40);
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
        }

        &:hover {
          &::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.811' height='16.121' viewBox='0 0 14.811 16.121'%3E%3Cg id='Group_13019' data-name='Group 13019' transform='translate(-15.25 -6.939)'%3E%3Cline id='Line_8' data-name='Line 8' x2='13' transform='translate(16 15)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cline id='Line_9' data-name='Line 9' y1='7' x2='6.857' transform='translate(22.143 15)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cline id='Line_10' data-name='Line 10' x2='6.857' y2='7' transform='translate(22.143 8)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
          }
        }
      }
    }

    &__viewall {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1.25rem;
      margin: 1.85rem 0;

      &--btn {
        @include cta-round-button($themeRed, $white, 40);
      }
    }
  }

  &__card {
    display: flex;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    opacity: 1;
    z-index: 9;
    aspect-ratio: 298 / 400;

    &--cross {
      @include cta-cross-round(100);
      position: absolute;
      margin-left: -1rem;
      margin-top: -1rem;
    }

    p {
      margin: 0.5rem 0;
    }

    @include bgimageconfig();

    &__header {
      display: flex;
      flex-direction: column;
      color: $white;
      padding: 0 1rem;
      background: -webkit-linear-gradient(#00000014, $themeGray);
    }

    &--title {
      margin: 0 !important;
      @include font-size(12.5);
      line-height: 1.2;
    }

    &--label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      @include font-size(8.15);
      font-weight: 400;
      line-height: 1.2;
    }

    &--divider {
      width: 1px;
      height: 0.8rem;
      background-color: $white;
    }

    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
    }

    &__cta {
      display: flex;
      flex-direction: row;
      justify-content: right;
      margin: 0.5rem 0.5rem 0 auto;

      @include cta-round-button($white, $themeRed, 40);

      &:hover {
        .people__cardopen {
          display: flex;
          opacity: 1;
          transform: translateY(0);
        }

        .people__card {
          display: none;
          opacity: 0;
          height: 0;
          overflow: hidden;
          transition:
            visibility 0s,
            opacity 0.5s linear;
        }
      }
    }

    &--btn {
      @include cta-plus-round(16);
    }
  }

  &__cardopen {
    background: $chipGray;
    display: none;
    opacity: 0;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.5s;
    transform: translateY(100%);
    width: 100%;
    aspect-ratio: 298 / 400;

    p {
      margin: 0.5rem 0;
    }

    &__cta {
      display: flex;
    }

    &--closebtn {
      @include cta-white-cross-round(40);
      transform: rotate(90deg);
      margin: 0.5rem;
    }

    &--title {
      margin: 0.5rem 0;
      @include font-size(12.5);
      font-weight: 600;
    }

    &--label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 0.5rem;
      @include font-size(8.15);
      font-weight: 400;
    }

    &--divider {
      width: 1px;
      height: 0.8rem;
      background-color: $themeRed;
    }

    &__social {
      display: flex;
      align-content: center;
      gap: 1rem;
    }

    &--profile {
      display: flex;
      a {
        color: $accentRed;
        @include font-size(10);
        font-weight: 300;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        gap: 1.25rem;
        padding: 1rem 0;

        &:before {
          @include rightLongArrow();
        }
      }
    }

    &--email {
      word-break: break-all;
    }

    &__header {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      color: $black;
      padding: 0 1rem;
      transition: 2.5s;
      transform: translateY(-100%);
      justify-content: space-evenly;
    }
  }

  .people__grid {
    list-style: none;
    justify-content: flex-start;
    gap: 1rem;

    .glide__slide {
      margin-top: 0;
      flex: 0 0 90%;
      max-width: 90%;
    }
  }

  ul {
    list-style: none;
  }

  &__remove-popup {
    flex-direction: row;
    gap: 1rem;
    padding: 2.5rem;
    display: none;
    background: $white;
    width: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 0 0 -20%;
    /* add negative left margin for half the width to center the div */
    cursor: default;
    z-index: 10001;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex: 0 0 85%;
    }

    &__cross {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex: 0 0 15%;
    }

    &--title {
      @include font-size(15.6);
    }

    &--desc {
      @include font-size(10);
    }

    &--cta {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include font-size(10);
      font-weight: 300;
      gap: 1rem;
      cursor: pointer;

      &::before {
        content: '';
        @include cta-round-button($accentRed, $white, 40);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &__popupbg {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 2000px;
    width: 100%;
    cursor: pointer;
    z-index: 1000;
    /* high z-index */
    background: $black;
    /* fallback */
    background: rgba(0, 0, 0, 0.75);
  }

  &-carousel {
    &__carousel {
      padding-top: toRem(30);

      .swiper-scrollbar {
        background-color: $borderGray;
        .swiper-scrollbar-drag {
          background-color: $white;
        }
      }

      .swiper-slide {
        min-height: toRem(345);
      }
    }

    &__no-results {
      @extend .h5;
      padding: toRem(20) 0;
    }

    .slides-hidden {
      padding: 0;
    }
  }
}

.bgtheme-gray {
  background-color: $themeGray;
}
