@function to4dp($value) {
  @return round($value * 10000) / 10000;
}

@function pxToRem($value) {
  @return ($value / 16px) * 1rem;
}

@function auto-clamp(
  $min-size-px,
  $max-size-px,
  $max-width-px: 1440px,
  $scaling-unit: 100vw
) {
  $min-size: pxToRem($min-size-px);
  $max-size: pxToRem($max-size-px);
  $max-width: pxToRem($max-width-px);
  @return clamp(
    #{to4dp($min-size)},
    #{to4dp($max-size * $scaling-unit / $max-width)},
    #{to4dp($max-size)}
  );
}
