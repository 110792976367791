.dacb {

    &-banner-heading1 {
        font-size: 3.06rem !important;
        line-height: 1.5 !important;
        font-weight: 400 !important;
        margin: 0 !important;
    }

    &-heading1 {
        font-size: 2.4rem !important;
        line-height: 1.5 !important;
        font-weight: 300 !important;
    }

    &-heading2 {
        font-size: 1.9rem !important;
        font-weight: 300 !important;
    }

    &-heading3 {
        font-size: 1.56rem !important;
        font-weight: 300 !important;
    }

    &-para1 {
        font-size: 1.25rem !important;
        font-weight: 200 !important;
    }


    &-cta-rightarr {
        @include font-size(10);
        font-weight: 300 !important;
        color: $accentRed !important;
        display: flex !important;

        a {
            justify-content: flex-start !important;
            align-items: center !important;
            display: flex !important;
            gap: 1.25rem !important;
            padding: 2.75rem 0 !important;
            color: $accentRed !important;
            &::before {
                @include rightLongArrow();
            }
    }
    }

    &--down-cheveron {
        &:after {
            display: flex;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='10' viewBox='0 0 7.298 4.674'%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l3.122,3.086,3.122-3.086' transform='translate(1039.819 -6170.467)' fill='none' stroke='%23df4661' stroke-width='1.5'/%3E%3C/svg%3E%0A");
            content: "";
            border: 0;
            margin-left: 0.625em;
            width: 12px;
            height: 8px;
            display: inline-block;
            background-repeat: no-repeat;
            transition: 0.1s ease-in-out;
            color: $secondaryRed;
        }
    }


    &-cta-plusleft {
        display: flex;
        a {
            display: flex;
            justify-content: center;
            align-self: flex-start;
            gap: 1rem;
            @include font-size(10);
            padding: 1.25rem 0;

            &::before {
                content: '';
                @include cta-plus();
            }
        }
    }

    &-cta-roundedarr {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 1.2rem;
            color: $themeGray;
            text-decoration: none;
            @include font-size(10);
            &::before {
                content: '';
                @include cta-round-button($accentRed, $white, 40);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
            }
    }
}