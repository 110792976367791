.servov{
    display: flex;
    flex-direction: column;
    padding: 5rem 1rem;
    

    &__content{
        &--heading{
            @include font-size(19.4);
            font-weight: 300;
        }
        &--subheading{
            @include font-size(12.5);
            font-weight: 200;
        }
        &--para{
            @include font-size(10);
            font-weight: 200;
        }

        &__cta {

            margin-top: 1.875rem;
    
            &--ahref {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 1.2rem;
                color: $themeGray;
                text-decoration: none;
                @include font-size(10);
                &::before {
                    content: '';
                    @include cta-round-button($accentRed, $white, 40);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                }
            }
    
        }
    }

    &__cats{
        padding-top: 3.75rem;
        &--heading{
            @include font-size(12.5);
            font-weight: 300;
        }


        &__list{
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: .93rem;
            margin: 0;
            
            
            li{
                               list-style: none;
                display: flex;
                align-items: center;

                &:before {
                    position: absolute;
                    content: '\2212';
                    height: 20px;
                    width: 20px;
                    color:$themeRed;
                
                }

                a{
                    padding-left: 1rem;
                    @include font-size(12.5);
                    font-weight: 200;
                }
            }
        }
    }
}