.actioncard {
  display: flex;
  padding: 0;
  @include bgimageconfig();

  .align-center {
    align-items: center;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    text-align: center;

    width: 100%;

    &-default {
      padding: 5.93rem 1.87rem;
    }

    &-left {
      padding: 10rem 1.87rem;
      text-align: left;
    }

    &-right {
      padding: 10rem 1.87rem;
      text-align: right;
    }
  }

  &--title {
    @include font-size(8.12);
    margin: 0;
  }

  &--heading {
    @include font-size(24.38);
    margin: 0;
    padding: 0.93rem 0;
    font-weight: normal;
  }

  &--desc {
    @include font-size(12.5);
    margin: 0;
    padding: 0.625rem 0;
  }

  &-default {
    padding: 5.93rem 1.87rem;
  }

  &-left {
    padding: 10rem;
  }

  &-center {
    padding: 10rem;
  }

  &__cta {
    margin-top: 1.875rem;

    &--ahref {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1.2rem;
      color: $themeGray;
      text-decoration: none;
      @include font-size(10);
      &::before {
        content: '';
        @include cta-round-button($accentRed, $white, 40);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
