.service{
    
    .subsector-carousel{
        padding: 6.25rem 3rem;
        &__header {
            display: flex;
            flex-direction: row;
    
            &__left {
                flex: 0 0 20%;
                h3{
                    @include font-size(24.38);
                    font-weight: normal;
                }
            }
    
            &__right {
                align-items: flex-start;
               flex: 0 0 80%;
               p{
                   max-width: 60%;
               }
            }
        }
    }

    .glide__slides {
        
        .glide__slide {

        }
    }


    
}