.servov{
    
    flex-direction: row;
    padding: 6.25rem 3rem;
    gap: 10rem;

    &__content{
        flex: 0 0 45%;
        &--heading{
            @include font-size(24.3);
        }

        &__cta {

        }
    }


    &__cats{

        flex: 0 0 20%;
        padding-top: 4.56rem;
        &--heading{
            @include font-size(15.6);
            font-weight: 300;
        }

        &__list{
          
        }

    }
 
}