// =======================================
//    #UTILITY - VISUALLY-HIDDEN
//
// To be used on links (etc) that should not
// display their text, ex. a icon button.
//
// https://a11yproject.com/posts/how-to-hide-content/
// =======================================

@mixin visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.hidden,
.visually-hidden {
  @include visually-hidden;
}

.display-element{
  display: flex !important;
  opacity: 1 !important;
}

.hide-element{
  display: none !important;
  opacity: 0 !important;
  height: 0 !important;
  transition: opacity 3s ease-out;
  overflow: hidden
}

.visible-element{
  visibility: visible !important;
}

.visible-hidden{
  visibility: hidden !important;
}



.stop-scrolling{
  overflow: hidden;
}