.sector-listing {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.88rem 1rem;
  gap: 2.5rem;

  * {
    -webkit-tap-highlight-color: transparent;
  }

  &__header {
    display: flex;
    flex-direction: row;
    padding: 1.25rem 0;
    gap: 0.625rem;
    width: 100%;

    &--left {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 0 0 90%;
    }

    &--right {
      display: flex;
      justify-content: flex-end;
      flex: 0 0 10%;
    }

    .herobanner__txtarrow--arrow {
      @include downLongRedArrow();
    }

    .herobanner__txtarrow--label {
      width: max-content;
    }

  }

  &--title {
    @include font-size(30.63);
    font-weight: normal;
    margin: 0;
  }

  &--desc {
    @include font-size(12.5);
    margin: 0;
    font-weight: 300;
  }

  &__container {
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: $themeGray;
    width: 100%;
  }


  &__cardopen {
    background: $white;
    padding: 0 1.25rem;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    transition: opacity 3s ease-out;
    opacity: 1;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid $cardBorderGray;
    border-top: 3px solid $themeRed;


    &__header {
      padding: 1rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__learnmore {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
    }

    &--title {
      bottom: 20;
      font-weight: 400;
      @include font-size(14);
      text-align: left;
      color: $themeGray;
      margin: 0;
    }

    &--closebtn {
      visibility: hidden;
      @include font-size(14, 1);
      transform: rotate(45deg);
      @include cta-round-button($white, $accentRed, 40);
    }

    &--learnbtn {
      @include cta-round-button($accentRed, $white, 40);
    }

    &--desc {
      @include font-size(10);
      text-align: left;
      color: $themeGray;
    }

    &--learntxt {
      @include font-size(10);
      text-align: left;
      color: $themeGray;
    }

    &--divider {
      width: 100%;
      height: 1px;
      border: 1px solid $offWhite;
      margin: 2rem 0;
    }


    &__services {
      background: $white;
      padding: 0;
      margin: 0;

      p {
        text-align: left;
        margin: 0;
      }

      ul {
        list-style: none;
        padding: 0;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;

        li {
          display: inline-flex;
          flex: 0 0 100%;
          padding: .8rem 0;
          justify-content: space-between;
          align-items: center;

          a {
            text-decoration: none;
            color: $themeGray;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &::after {
              content: '\2192';
              display: inline-block;
              color: $accentRed;
              @include font-size(16);
              line-height: 1.38em;
            }
          }

        }
      }
    }
  }

  &__card {
    overflow: hidden;
    width: 100%;
    height: 250px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
    cursor: pointer;
    opacity: 1;
    z-index: 9;
    display: flex;
    background: $white;

    &:after {
      @include background-shade;
    }

    ul {
      list-style: none;
    }

    &__content {
      display: flex;
      flex-direction: column;
      z-index: 10;
      padding: 1rem 2rem;
      overflow: hidden;
      justify-content: space-between;
      height: 90%;
      width: 100%;
    }

    &__cta {
      display: none;
      flex-direction: row;
      justify-content: right;

    }

    &--btn {
      @include cta-round-button($white, $themeRed, 32);

    }

    &--title {
      font-weight: 600;
      @include font-size(15.6);
      text-align: left;
      color: $white;
      margin: 0;
      padding: 0 0 1rem 0;
    }
  }

  &-header {
    padding: 0 3.4rem
  }



}