.video{
    display: flex;
    flex-direction: column;
    padding: 1rem ;
    
    p{
        margin: 0;
    }

    &--title{
        @include font-size(20);
        font-weight: 300;
    }

    &--subtitle{
        @include font-size(12);
    }
}


.podcast{
    display: flex;
    flex-direction: column;
    padding: 1rem ;
}