.article {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 1rem;
  row-gap: 1.8rem;

  p {
    margin: 0.6rem 0;
  }

  .people {
    &__cardopen__content {
      p {
        margin: 0;
      }
    }
    &-carousel {
      &__carousel {
        .swiper-scrollbar {
          background-color: $borderGray;
          .swiper-scrollbar-drag {
            background-color: $themeGray;
          }
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.25rem;
    border-bottom: 1px solid $offWhite;
    padding-bottom: 1rem;

    &--title {
      @include font-size(19.38);
      font-weight: normal;
    }

    &--count {
      @include font-size(19.3);
    }
    &--imgwrap {
      img {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    &__info {
      display: flex;
      gap: 0;
      align-items: flex-start;
      flex-direction: column;
    }

    &--publisher,
    &--publishby {
      //   flex: 0 0 100%;
      padding: 0.2rem;
    }

    p {
      margin: 0;
    }

    &-separator {
      display: none;
    }
  }

  &__overview {
    display: flex;
    flex-direction: column;
    @include font-size(10);

    &--title {
      @extend .h3;
      font-weight: 300;
      margin-top: toRem(50);
    }

    &--subtitle {
      @include font-size(12.5);
    }

    &--para {
      @include font-size(10);
    }
  }

  &__video {
    display: flex;
    flex-direction: column;

    &--tag {
      @include font-size(10);
    }
  }

  &__headline {
    &__title {
      display: flex;
      flex-direction: row;
      @include font-size(24.37);
      font-weight: 300;

      &--left {
        display: flex;
        flex: 0 0 4%;
        color: $secondaryRed;
      }

      &--right {
        flex: 0 0 96%;
      }
    }

    &--para {
      @include font-size(10);
      color: $gray900;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    &--title {
      @include font-size(15.5);
    }

    &--para {
      @include font-size(10);
    }

    &--imgwrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      min-width: 342px;

      img {
        width: 100%;
      }
    }

    &--subtag {
      @include font-size(10);
      color: $gray900;
    }
  }

  &__social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2.5rem;
    flex-wrap: wrap;
    border-top: 1px solid $offWhite;
    border-bottom: 1px solid $offWhite;
    padding: 1.6rem 0;
    margin: toRem(80) 0;

    &--message {
      flex: 1 0 80%;
      justify-content: right !important;
    }
    &--label {
      @include font-size(10);
    }

    &--icons {
      display: flex;
      gap: 1.8rem;
      margin-right: auto;

      img {
        height: 20px;
      }
    }

    &__cta {
      display: flex;
      flex-direction: column;
      width: 100%;

      &--save,
      &--savehref {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        column-gap: toRem(20);
        margin-top: toRem(40);
      }
      &--downloadpdf {
        column-gap: toRem(20);
        align-items: center;
        display: flex;
        cursor: pointer;
        background: transparent;
        border: none;
        color: rgb(74, 73, 72);
        font: inherit;
        padding: 0;

        &:before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.263' height='20.659' viewBox='0 0 19.263 20.659'%3E%3Cg id='Group_14355' data-name='Group 14355' transform='translate(11200.527 5244.604)'%3E%3Cline id='Line_880' data-name='Line 880' y2='19.604' transform='translate(-11191 -5244.604)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3Cpath id='Path_8149' data-name='Path 8149' d='M-1039.292,6171l9.1,9,9.1-9' transform='translate(-10160.708 -11405)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
          content: '';
          border: 0;
          width: 22px;
          height: 20px;
          display: inline-block;
          background-repeat: no-repeat;
          transition: 0.1s ease-in-out;
          color: $secondaryRed;
        }
      }

      &--save,
      &--savehref {
        &:before {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'%3E%3Cg id='Group_14357' data-name='Group 14357' transform='translate(-1026 -1655.209)'%3E%3Cline id='Line_1186' data-name='Line 1186' y2='18' transform='translate(1035 1655.209)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3Cline id='Line_1187' data-name='Line 1187' x1='18' transform='translate(1026 1664.209)' fill='none' stroke='%23ce5163' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
          content: '';
          border: 0;
          width: 20px;
          height: 20px;
          display: inline-block;
          background-repeat: no-repeat;
          transition: 0.1s ease-in-out;
          color: $secondaryRed;
        }
      }
    }
  }

  &__authcontacts {
    display: flex;
    flex-direction: column;
    gap: toRem(16);
  }

  &__author {
    flex: 0 0 100%;
    max-width: 100%;

    &--title {
      @extend .h4;
      font-weight: 300;
    }

    &__cards {
      display: flex;
      width: 100%;
      gap: 1rem;
    }

    &__cardwrapper {
      width: 100%;
    }
  }

  &__keycontacts {
    // .swiper-slide {
    //   max-width: calc(50% - 8px) !important;
    // }

    &--title {
      @extend .h4;
    }

    .swiper {
      padding: 0 !important;
    }

    &-list {
      display: flex;
      height: auto !important;

      .swiper-slide {
        min-width: 250px !important;
        aspect-ratio: 298 / 400;
        margin-right: 1.2rem;

        &:last-of-type {
          margin-right: 0 !important;
        }
      }
    }
  }

  &__relcontent {
    background: $white;
    padding: 0 toRem(16);
    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1.87rem;
    }

    &--title {
      @extend .h3;
      font-weight: normal;
    }

    &--viewcta {
      display: flex;
      align-items: center;

      &--href {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.2rem;
        color: $themeGray;
        text-decoration: none;
        @include font-size(10);
        &::before {
          content: '';
          @include cta-round-button($accentRed, $white, 40);
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
        }

        &:hover {
          &::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14.811' height='16.121' viewBox='0 0 14.811 16.121'%3E%3Cg id='Group_13019' data-name='Group 13019' transform='translate(-15.25 -6.939)'%3E%3Cline id='Line_8' data-name='Line 8' x2='13' transform='translate(16 15)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cline id='Line_9' data-name='Line 9' y1='7' x2='6.857' transform='translate(22.143 15)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3Cline id='Line_10' data-name='Line 10' x2='6.857' y2='7' transform='translate(22.143 8)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
          }
        }
      }
    }

    &__carousel {
      display: flex;
      background: $white;

      .swiper-slide {
        width: fit-content !important;

        .articlecardv2 {
          max-width: toRem(414);

          img {
            aspect-ratio: 1 / 1;
            width: 100%;
            object-fit: cover;
          }
          &__detail {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-clamp: 2;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &__cta {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: $accentRed;

            &::before {
              @include rightLongArrow()
            }

            a {
              color: $accentRed;
            }
          }
          &__label {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            &--divider {
              width: 1px;
              height: 1rem;
              background-color: currentColor;
            }
          }
        }
      }
    }
  }

  &--hrdiv {
    width: 100%;
    height: 1px;
    background-color: $offWhite;
    margin-bottom: toRem(40);
  }

  &__grid {
    display: grid;
    grid: auto / 1fr;
    row-gap: toRem(60);
    padding: toRem(50) 0;
    margin: 0;

    @media only screen and (min-width: $tablet) {
      grid: auto / 1fr 1fr;
      gap: 2rem;
    }

    &__li {
      display: flex;
      flex: 0 0 100%;
    }

    .w-100 {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .articlecard {
      &--imgwrap {
        width: 100%;
        display: flex;
        min-width: unset;
        max-width: unset;
        img,
        &.articlecard__placeholder {
          width: 100%;
          aspect-ratio: 16/9;
          object-fit: cover;
        }
      }
      &__cta {
        margin-top: auto;
      }
    }
  }

  &__gated {
    display: flex;
    flex-direction: column;
    padding: 0;

    .red {
      color: $themeRed;
    }

    &--error,
    &--success {
      justify-content: center;
      align-items: center;
      display: none;

      @include font-size(12);
    }

    &--error {
      color: $themeRed;
    }

    &--success {
      color: $green;
    }

    &--title {
      @include font-size(19.38);
      font-weight: normal;
    }

    &--tag {
      @include font-size(12);
    }

    .input {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
      padding-top: 10px;

      & + .input {
        margin-top: 10px;
      }
    }

    .input_label {
      color: $gray900;
      position: absolute;
      top: 20px;
      transition: all 0.3s;
      @include font-size(8.2);
    }

    .input_field {
      border: 0;
      padding: 0.32rem 0;
      z-index: 1;
      background-color: transparent;
      border-bottom: 1px solid $offWhite;
      font: inherit;
      @include font-size(10);
      line-height: 26px;

      &:focus,
      &:valid {
        outline: 0;
        border-bottom-color: $themeGray;
      }

      & + .input_label {
        color: $gray900;
        transform: translateY(-25px);
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
    }

    &--form {
      display: flex;
      flex-direction: column;
      padding: 2.5rem 0;
      border-top: 1px solid $offWhite;
      gap: 2rem;

      &::after {
        content: '';
        border: 0;
        width: 25px;
        height: 20px;
        right: 0px;
        position: absolute;
        bottom: 0.5rem;
        display: inline-block;
        background-repeat: no-repeat;
        transition: 0.1s ease-in-out;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25.923' height='15.988' viewBox='0 0 25.923 15.988'%3E%3Cg id='Orion_eye' transform='translate(-1.75 -13.75)'%3E%3Cpath id='layer2' d='M14.712,14.5C7.592,14.5,2.5,21.744,2.5,21.744s5.092,7.244,12.212,7.244,12.212-7.244,12.212-7.244S21.832,14.5,14.712,14.5Z' fill='none' stroke='%23e7a8b1' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3Ccircle id='layer1' cx='4.14' cy='4.14' r='4.14' transform='translate(10.572 17.398)' fill='none' stroke='%23e7a8b1' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3Cpath id='layer1-2' data-name='layer1' d='M28.186,29.5a1.656,1.656,0,0,0,1.449,2.484' transform='translate(-14.923 -8.791)' fill='none' stroke='%23e7a8b1' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E%0A");
      }

      .terms-cb {
        display: flex;
        align-items: center;
        gap: 1rem;
      }
    }

    &__fields {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
      padding-top: 2.5rem;
    }

    &__submit {
      display: flex;

      &--ctalink {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.25rem;
        text-decoration: none;
        color: $themeGray;
        @include font-size(10);
        font-weight: 300;

        &::before {
          content: '';
          @include cta-round-button($accentRed, $white, 40);
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
        }
      }
    }

    input[type='checkbox'] {
      accent-color: $themeGray;
      box-shadow: none;
      width: 20px;
      height: 20px;
      margin: 0;
      cursor: pointer;
      border: 2px solid $themeGray;

      &:checked ~ label {
        &:before {
          border: 2px solid $themeGray;
        }

        &:after {
          transform: rotate(-45deg) scale(1);
        }
      }

      &:focus + label::before {
        outline: 0;
      }
    }
  }

  &__remove-popup {
    flex-direction: row;
    gap: 1rem;
    padding: 2.5rem;
    display: none;
    background: $white;
    width: 40%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 0 0 -20%; /* add negative left margin for half the width to center the div */
    cursor: default;
    z-index: 10001;
    border-radius: 4px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.9);

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex: 0 0 85%;
    }

    &__cross {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex: 0 0 15%;
    }
    &--title {
      @include font-size(15.6);
    }

    &--desc {
      @include font-size(10);
    }

    &--cta {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @include font-size(10);
      font-weight: 300;
      gap: 1rem;
      cursor: pointer;

      &::before {
        content: '';
        @include cta-round-button($accentRed, $white, 40);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.369' height='16.797' viewBox='0 0 9.369 16.797'%3E%3Cpath id='Path_11838' data-name='Path 11838' d='M-1039.292,6171l7.871,7.781,7.871-7.781' transform='translate(-6170.467 -1023.022) rotate(-90)' fill='none' stroke='%23fff' stroke-width='1.5'/%3E%3C/svg%3E%0A");
      }
    }
  }

  &__popupbg {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 2000px;
    width: 100%;
    cursor: pointer;
    z-index: 1000; /* high z-index */
    background: $black; /* fallback */
    background: rgba(0, 0, 0, 0.75);
  }
}
